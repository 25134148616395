import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'
import { charCount, charNoSpacesCount, pageCount } from './DocumentInfoBackend'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';

const Toc = styled.section`
  > div{
    cursor: pointer;
  }
  
  > div + div {
    margin-top:5px;
  }

  > div.level1 {
    padding-left:0px;
  }

  > div.level2 {
    padding-left:5px;
  }

  > div.level3 {
    padding-left:10px;
  }

  > div.level4 {
    padding-left:15px;
  }

  > div.level5 {
    padding-left:20px;
  }

  > div.level6 {
    padding-left:25px;
  }
`

interface IDocumentInfoProps {
    handInId: string;
    onGotoHeadingRequest: (e: number) => void;
    text: string;
}

function DocumentInfo(p: IDocumentInfoProps) {
    const { t } = useTranslation();

    const handleHeadingClick: MouseEventHandler<HTMLDivElement> = (e) => {
        const s = e.currentTarget.attributes.getNamedItem("data-i")?.value;
        if (s) {
            const i = parseInt(s);
            p.onGotoHeadingRequest(i - 1);
        }
    }

    function getTextFromHtml(html: string) {
        const x = document.createElement('div');
        x.innerHTML = html;
        return x.innerText;
    }
    
    function toc(text: string) {
        const headliner = /<h(\d).*?>(.*?)<\/h\1>/mg;

        const matches = text.matchAll(headliner);
        let i = 0;
        const headers = Array.from(matches, x => {
            const className = "headline level" + x[1];
            i++;

            let headerText = getTextFromHtml(x[2]);
            
            return (<div key={"Toc" + i.toString()} data-i={i} className={className} onClick={handleHeadingClick}>
                {headerText}           
            </div>)
        })

        if (i === 0) {
            headers.push(
                <div key={"NoTOC"}>{t("noHeadlines")}</div>
            );
        }

        return headers
    }
    
    const tooltip = (s: string) => <Tooltip title={s}><HelpOutlineIcon fontSize="small" /></Tooltip>

    return (<article style={{ height: "100%", overflow:"auto" }}>
            <section>
                <h2>{t("handin")}</h2>
            </section>
            <section>
                <h2>{t("statistic")}</h2>
            <div style={{ marginBottom: "6px" }}>
                    {t("charactersWithSpaces")}: <span>{charCount(p.text)}</span>
                </div>
            <div style={{ marginBottom: "6px" }}>
                    {t("charactersWithoutSpaces")}: <span>{charNoSpacesCount(p.text)}</span>
                </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: "6px"}}>
                <span>{t("pages")}:&nbsp;</span> <span>{pageCount(p.text)}&nbsp;</span>  {tooltip(t("normalPage"))}
                </div>
            </section>
            <Toc>
                <h2>{t("tableOfContents")}</h2>
                {toc(p.text)}
            </Toc>
        </article>
    )
}

export default DocumentInfo;