import React, {useEffect, useState} from 'react';
import Async from "react-async";
import { ApiClient } from '../Api'
import { useParams } from "react-router-dom";
import { SaveStatus } from '../Components/SaveMonitor';
import SaveStatusOverlay from '../SaveStatusOverlay';
import { ThemeProvider } from 'styled-components';
import Theme from '../Components/Theme';
import {NotesCollection as NotesCollectionData} from '../Swagger';
import LanguageSelector from '../Components/LanguageSelector';
import MuiThemeProvider from "../Components/MuiThemeProvider";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { useBeforeunload } from 'react-beforeunload';
import {useTranslation} from "react-i18next";
import NotesCollection from './NotesCollection';

interface IProps {
    id: string;
    notesCollection: NotesCollectionData;
}

function MainContent(p: IProps) {
    const [theme] = useState(Theme);
    const [status, setStatus] = useState(SaveStatus.Saved);
    const { t } = useTranslation();

    const showSaveStatus = status !== SaveStatus.Saved && status !== SaveStatus.Saving;
    
    useBeforeunload(e => {
        if (showSaveStatus) e.preventDefault()
    });

    useEffect(() => {
        document.title = t('mainTitle');
    });
    
    return (
        <MuiThemeProvider>
            <ThemeProvider theme={theme}>
                <Box display="flex" flexDirection="column" sx={{ p: 3 }}>
                    <AppBar>
                        <Toolbar style={{ backgroundColor: "white", color: "black" }}>
                            <img src="/digitaleksamen.logo.png" alt="logo" style={{marginRight: "20px"}} />

                            <Typography variant="h3" noWrap component="div">Noter</Typography>
                            <div style={{ marginLeft: "auto" }}>
                                <span style={{ marginRight: "20px" }}>{p.notesCollection.displayName || ""}</span>
                                <LanguageSelector />                                
                            </div>
                        </Toolbar>
                    </AppBar>
                    
                    <Box>
                        <Toolbar />
                        <SaveStatusOverlay show={showSaveStatus} status={status}>
                            <NotesCollection onSaveStatusChanged={setStatus} id={p.id} notes={p.notesCollection.notes || []} />
                        </SaveStatusOverlay>
                    </Box>
                </Box>
            </ThemeProvider>
        </MuiThemeProvider>
    )
}

function NotesApp() {
    const { id } = useParams();
    const guid = id || "";

    const Load = () => {
        return ApiClient.instance.api.editorNotescollectionDetail(guid).then(notesCollection => {
            return { 'collection': notesCollection.data, 'id': guid }
        });
    };
    
    return (
        <Async promiseFn={Load}>
            {({ data, error, isLoading }) => {
                if (isLoading) return "Loading..."
                if (error) return "Failed";
                if (data)
                    return (
                        <MainContent id={guid} notesCollection={data.collection} />
                    )
            }}
        </Async>
    )
}

export default NotesApp;
