import styled from "styled-components";
import {useTranslation} from 'react-i18next';
import {AppendixFile} from "../Swagger";
import {Box, Button, CircularProgress, List, ListItem, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import EditorBroadcastChannel from "../EditorBroadcastChannel";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { ApiClient } from "../Api";
import { appendixUrl, isImage } from "./AppendicesLogic";
import {broadcastChannelAvailable} from "../BroadcastChannelStub";

const ThumbImageStyled = styled.img`
  width:80%;
  height: 80%;
`

interface IExtraMaterialInfo {
    handInId: string;
    appendices: AppendixFile[];
    insertImage: (path: string) => void;
    setAppendices: React.Dispatch<React.SetStateAction<AppendixFile[]>>;
}

function NoAppendices() {
    const {t} = useTranslation();

    return <div>{t("noMaterials")}</div>;
}

interface IAppendixListProps {
    handInId: string;
    appendices: AppendixFile[];
    insertImage: (path: string) => void;
}

function AppendixList(p: IAppendixListProps) {
    const {i18n} = useTranslation();

    const sortedAppendices = p.appendices.sort((a, b) => a.name && b.name ? a.name.localeCompare(b.name, i18n.language) : a.name ? -1 : b.name ? 1 : 0);
    
    return (<List>
        {sortedAppendices.map(a => 
            <ListItem key={a.id}>
                {isImage(a) &&<Box alignItems={"center"} display={"flex"}>
                    <ArrowBackIosNewIcon style={{ cursor: 'pointer' }} onClick={() => p.insertImage(appendixUrl(p.handInId, a))}/>
                    <ThumbImageStyled src={appendixUrl(p.handInId, a)} />
                </Box>
                }
                {!isImage(a) &&
                    <span>{a.name}</span>
                }
            </ListItem>
        )}
    </List>)
}

function ExtraMaterialInfo(params: IExtraMaterialInfo) {
    const {t} = useTranslation();
    const [canOpenTab, setCanOpenTab] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const bc = new EditorBroadcastChannel();

        bc.onExtraMaterialTabOpened = () => {
            setCanOpenTab(false);
        }

        bc.onExtraMaterialTabClosed = () => {
            setCanOpenTab(true);
        }

        return () => {
            bc.close();
        }
    });

    function reloadAppendices() {
        setLoading(true);
        ApiClient.instance.api.editorHandinDetail(params.handInId).then(handIn => {
            params.setAppendices(handIn.data.appendices || []);
        }).finally(() => {
            setLoading(false);
        })
    }
    
    const noAppendices = params.appendices.length === 0;
    
    return (<article>
            <section style={{width: "100%"}}>
                <div>
                    {canOpenTab ? <Button target="_blank" href={'/handin/' + params.handInId + '/extramaterial/appendices'}>{t("addMaterials")}</Button> :
                        <Typography>{t('tabIsOpen')}</Typography>}
                </div>

                <div style={{maxHeight: '500px', overflowY: 'auto', overflowX: 'hidden'}}>
                {noAppendices ? <NoAppendices /> : <Box>
                    <AppendixList appendices={params.appendices} handInId={params.handInId} insertImage={params.insertImage}/>
                    {!broadcastChannelAvailable && 
                        <Box>
                            {!loading && <Button onClick={reloadAppendices}>{t("reloadAppendices")}</Button>}
                            {loading && <CircularProgress/>}
                        </Box> 
                    }
                </Box> }
                </div>
            </section>
        </article>
    )
}

export default ExtraMaterialInfo;