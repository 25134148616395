import React, {useState} from 'react';
import {HttpResponse, Note} from '../Swagger';
import {ApiClient} from '../Api'
import {SaveStatus} from '../Components/SaveMonitor';
import {Button, Box, Grid, Typography, CircularProgress, Alert} from "@mui/material";
import {useTranslation} from "react-i18next";
import FileInput from "../Components/FileInput";
import {v4 as Guid} from 'uuid';

interface IProps {
    id: string;
    onSaveStatusChanged: (s: SaveStatus) => void;
    notes: Note[];
}

interface INoteItemProps {
    note: Note;
    id: string;
    deleteNote: (a: Note) => void;
}

const NoteItem = (p: INoteItemProps) => {
    const {t} = useTranslation();

    return <Grid container columns={16} wrap={"nowrap"} spacing={4} alignItems={"center"}>
        <Grid item xs={14}>
            <Typography noWrap component="span">{p.note.name}</Typography>
        </Grid>

        <Grid item>
            <Button variant="text" onClick={() => p.deleteNote(p.note)}>{t("delete")}</Button>
        </Grid>
    </Grid>
}


const NotesCollection = (p: IProps) => {
    const [notes, setNotes] = useState(p.notes ?? []);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState(false);
    const {t} = useTranslation();

    const deleteNote = (f: Note) => {
        if (f.id) {
            ApiClient.instance.api.editorNotescollectionNotesDelete(f.id, p.id).then(() => {
                setNotes(old => old.filter(x => f.id !== x.id));
            });
        }
    }

    const uploadFile = (f: File) => {
        setUploading(true);
        setError(false);

        let noteId = Guid()

        ApiClient.instance.api.editorNotescollectionNotesCreate(p.id, noteId, {
            File: f
        }).then(() => {
            return ApiClient.instance.api.editorNotescollectionNotesDetail(noteId, p.id).then((response: HttpResponse<Note, any>) => {
                setNotes(old => [...old, response.data]);
            });
        }).finally(() => {
            setUploading(false);
        }).catch(() => {
            setError(true);
        });
    }

    return (
        <div style={{width: "600px", margin: "auto"}}>
            <article style={{display: "flex", flexFlow: "column", flex: "1 0", height: "85vh"}}>
                <Box sx={{ml: "auto"}}>
                    {!uploading && <FileInput disabled={false} onFileSelect={uploadFile}>{t("uploadFile")}</FileInput>}
                    {uploading && <CircularProgress sx={{ml: 3}}/>}
                    {error && <Alert sx={{ml: 3}} severity={"error"}>{t('uploadError')}</Alert>}
                </Box>

                <section style={{display: "flex", flex: "1 0"}}>
                    <Grid container direction={"column"}>
                        {notes.map(e =>
                            <Grid style={{borderBottom: "1px solid gray"}} sx={{p: 2}} item key={e.id}>
                                <NoteItem note={e} deleteNote={deleteNote} id={p.id}/>
                            </Grid>
                        )}
                    </Grid>
                </section>
            </article>
        </div>
    )
}

export default NotesCollection;