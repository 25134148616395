import { Button } from '@mui/material';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {ApiClient} from '../Api'
import { appendixUrl } from '../SideMenu/AppendicesLogic';
import {AppendixFile, HttpResponse} from '../Swagger';

interface IProps {
    addAppendix: (a: AppendixFile) => void;
    replaceAppendix: (oldAppendix: AppendixFile, newAppendix: AppendixFile) => void;
    incrementAppendixCounter:() => void;
    nextAppendixCount: ()=> number;
    id: string;
}
interface IStateType {
    file: AppendixFile
}

function FreeDrawing(p: IProps) {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [drawingTool, setDrawingTool] = useState<any>();
    const [appendix, setAppendix] = useState<AppendixFile>();
    const { state } = useLocation();
   
    const addAppendix = () => {
        var name = appendix ? appendix.name : "Appendix " + p.nextAppendixCount() + ".png";
        ApiClient.instance.api.editorHandinFileUploadExtramaterialeImagedataCreate(p.id, {
            imageData: drawingTool.canvas.toDataURL({
                format: 'png',
                quality: 1 // Dette er ifølge dokumentationen kun for jpegs
            }),
            name: name
        }).then((response: HttpResponse<AppendixFile, any>) => {
            if (appendix) {
                p.replaceAppendix(appendix, response.data);
            } else {
                p.incrementAppendixCounter();
                p.addAppendix(response.data);
            }
            navigate("../appendices")
        });
    }

    useEffect(() => {
        const w: any = window;
        const d = new w.DrawingTool("#drawing-tool-container", {
            width: 900,
            height: 650,
            startWithHistoryPaused: true
        });
        if (state) {
            d.setBackgroundImage(appendixUrl(p.id, (state as IStateType).file),
                null,
                () => {
                    d.unpauseHistory();
                }
            );
            setAppendix((state as IStateType).file)
        } else {
            d.unpauseHistory();
        }
        setDrawingTool(d);
    }, [p.id, state]);

    return (
        <div>
            <div id="drawing-tool-container"/>
            <Button variant="contained" onClick={addAppendix}>
                {!appendix && t("addToExtraMaterial")}
                {appendix && t("save")}
            </Button>
        </div>);
}

export default FreeDrawing;