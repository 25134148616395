/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AppendixFile {
  /** @format uuid */
  id?: string;
  name?: string;
  include?: boolean;
}

export interface AssignmentFile {
  /** @format uuid */
  id?: string;
  name?: string | null;
}

export interface CompareRequest {
  oldHtml?: string;
  newHtml?: string;
}

export interface FileCreateRequest {
  uri?: string;
  name?: string | null;
}

export interface FinishResult {
  success?: boolean;
  content?: FinishResultContent;
}

export interface FinishResultContent {
  type?: string | null;
  content?: string | null;
}

export interface HandIn {
  /** @format uuid */
  id?: string;
  title?: string;
  studentName?: string;
  callbackPostUri?: string | null;

  /** @format date-time */
  start?: string;

  /** @format date-time */
  end?: string;

  /** @format date-time */
  lastUpdated?: string;

  /** @format date-time */
  finished?: string | null;

  /** @format uuid */
  notesCollectionId?: string;
  useWebcam?: boolean;
  useDrawing?: boolean;

  /** @format uuid */
  linksCollectionId?: string;
  useSpellCheck?: boolean;
  canReopen?: boolean;
  useCalculator?: boolean;

  /** @format uuid */
  assignmentFilesCollectionId?: string;
}

export interface HandInAnalysisModel {
  changes?: HandInChange[];
}

export interface HandInAppendix {
  /** @format uuid */
  id?: string;

  /** @format uuid */
  handInId?: string;
  uri?: string;
  name?: string;

  /** @format date-time */
  createdAt?: string;
  include?: boolean;

  /** @format date-time */
  deletedAt?: string | null;
}

export interface HandInChange {
  /** @format uuid */
  handInDataId?: string;

  /** @format int64 */
  deltaCount?: number;

  /** @format int64 */
  totalCount?: number;
  suspicious?: boolean;

  /** @format date-time */
  occuredAt?: string;
}

export interface HandInCreateRequest {
  title?: string;
  studentName?: string;
  fileUri?: string | null;
  callbackPostUri?: string | null;

  /** @format date-time */
  start?: string;

  /** @format date-time */
  end?: string;

  /** @format uuid */
  notesCollectionId?: string;

  /** @format uuid */
  linksCollectionId?: string;
  useWebcam?: boolean;
  useDrawing?: boolean;
  useSpellCheck?: boolean;
  useCalculator?: boolean;
  canReopen?: boolean;
}

export interface HandInCreateRequestV2 {
  title?: string;
  studentName?: string;
  callbackPostUri?: string | null;

  /** @format date-time */
  start?: string;

  /** @format date-time */
  end?: string;

  /** @format uuid */
  notesCollectionId?: string;

  /** @format uuid */
  linksCollectionId?: string;

  /** @format uuid */
  assignmentFilesCollectionId?: string;
  useWebcam?: boolean;
  useDrawing?: boolean;
  useSpellCheck?: boolean;
  useCalculator?: boolean;
  canReopen?: boolean;
}

export interface HandInData {
  /** @format uuid */
  id?: string;

  /** @format uuid */
  handInId?: string;
  content?: string;

  /** @format date-time */
  createdAt?: string;
}

export interface HandInModel {
  /** @format uuid */
  id?: string;

  /** @format uuid */
  notesCollectionId?: string;
  title?: string;
  studentName?: string;
  data?: string;
  hasAssignmentFile?: boolean;

  /** @format date-time */
  start?: string;

  /** @format date-time */
  end?: string;

  /** @format date-time */
  finished?: string | null;
  settings?: HandInSettings;
  appendices?: AppendixFile[];
  notes?: Note[];
  links?: Link[];
  assignmentFiles?: AssignmentFile[];
}

export interface HandInSettings {
  useWebcam?: boolean;
  useDrawing?: boolean;
  useSpellCheck?: boolean;
  useCalculator?: boolean;
  canReopen?: boolean;
}

export interface Link {
  /** @format uuid */
  id?: string;
  uri?: string;
  name?: string;
}

export interface LinkCreateRequest {
  uri?: string;
  name?: string;
}

export interface Note {
  /** @format uuid */
  id?: string;
  name?: string;
}

export interface NotesCollection {
  /** @format uuid */
  id?: string;
  displayName?: string;
  notes?: Note[];
}

export interface RenameExtramateriale {
  name?: string;

  /** @format uuid */
  appendixFileId?: string;
}

export interface UploadExtramaterialeImagedataData {
  imageData?: string | null;
  name?: string;

  /** @format uuid */
  appendixFileId?: string | null;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Arcanic.Editor.Web
 * @version 1.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Analysis
     * @name EditorHandinAnalysisDetail
     * @summary GetStats
     * @request GET:/api/editor/handin/{handInId}/analysis
     */
    editorHandinAnalysisDetail: (handInId: string, params: RequestParams = {}) =>
      this.request<HandInAnalysisModel, any>({
        path: `/api/editor/handin/${handInId}/analysis`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analysis
     * @name EditorHandinAnalysisTestDetail
     * @summary GetTestStats
     * @request GET:/api/editor/handin/{handInId}/analysis/test
     */
    editorHandinAnalysisTestDetail: (handInId: string, params: RequestParams = {}) =>
      this.request<HandInAnalysisModel, any>({
        path: `/api/editor/handin/${handInId}/analysis/test`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analysis
     * @name EditorHandinAnalysisHandindataDetail
     * @summary GetHandInData
     * @request GET:/api/editor/handin/{handInId}/analysis/handindata/{handInDataId}
     */
    editorHandinAnalysisHandindataDetail: (handInId: string, handInDataId: string, params: RequestParams = {}) =>
      this.request<HandInData, any>({
        path: `/api/editor/handin/${handInId}/analysis/handindata/${handInDataId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AppendicesApi
     * @name EditorHandinAppendicesDetail
     * @summary Get
     * @request GET:/api/editor/handin/{handInId}/appendices
     */
    editorHandinAppendicesDetail: (handInId: string, params: RequestParams = {}) =>
      this.request<AppendixFile[], any>({
        path: `/api/editor/handin/${handInId}/appendices`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AppendicesApi
     * @name EditorHandinAppendicesDetail2
     * @summary GetAppendix
     * @request GET:/api/editor/handin/{handInId}/appendices/{appendixId}
     * @originalName editorHandinAppendicesDetail
     * @duplicate
     */
    editorHandinAppendicesDetail2: (appendixId: string, handInId: string, params: RequestParams = {}) =>
      this.request<HandInAppendix, any>({
        path: `/api/editor/handin/${handInId}/appendices/${appendixId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AssignmentFilesCollectionApi
     * @name EditorAssignmentfilescollectionCreate
     * @summary CreateOrUpdateCollection
     * @request POST:/api/editor/assignmentfilescollection/{collectionId}
     */
    editorAssignmentfilescollectionCreate: (collectionId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/editor/assignmentfilescollection/${collectionId}`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AssignmentFilesCollectionApi
     * @name EditorAssignmentfilescollectionFilesCreate
     * @summary CreateFile
     * @request POST:/api/editor/assignmentfilescollection/{collectionId}/files/{fileId}
     */
    editorAssignmentfilescollectionFilesCreate: (
      collectionId: string,
      fileId: string,
      data: FileCreateRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/editor/assignmentfilescollection/${collectionId}/files/${fileId}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AssignmentFilesCollectionApi
     * @name EditorAssignmentfilescollectionFilesDelete
     * @summary DeleteFile
     * @request DELETE:/api/editor/assignmentfilescollection/{collectionId}/files/{fileId}
     */
    editorAssignmentfilescollectionFilesDelete: (fileId: string, collectionId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/editor/assignmentfilescollection/${collectionId}/files/${fileId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AssignmentFilesCollectionApi
     * @name EditorAssignmentfilescollectionFilesDetail
     * @summary Get
     * @request GET:/api/editor/assignmentfilescollection/{collectionId}/files/{fileId}
     */
    editorAssignmentfilescollectionFilesDetail: (fileId: string, collectionId: string, params: RequestParams = {}) =>
      this.request<AssignmentFile, any>({
        path: `/api/editor/assignmentfilescollection/${collectionId}/files/${fileId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AssignmentFilesCollectionApi
     * @name EditorAssignmentfilescollectionFilesDetail2
     * @summary GetMany
     * @request GET:/api/editor/assignmentfilescollection/{collectionId}/files
     * @originalName editorAssignmentfilescollectionFilesDetail
     * @duplicate
     */
    editorAssignmentfilescollectionFilesDetail2: (collectionId: string, params: RequestParams = {}) =>
      this.request<AssignmentFile[], any>({
        path: `/api/editor/assignmentfilescollection/${collectionId}/files`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompareHtml
     * @name EditorComparehtmlCreate
     * @summary Compare
     * @request POST:/api/editor/comparehtml
     */
    editorComparehtmlCreate: (data: CompareRequest, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/editor/comparehtml`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EditorApi
     * @name EditorHandinDetail
     * @summary Get
     * @request GET:/api/editor/handin/{handInId}
     */
    editorHandinDetail: (handInId: string, params: RequestParams = {}) =>
      this.request<HandInModel, any>({
        path: `/api/editor/handin/${handInId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EditorApi
     * @name EditorHandinCreate
     * @summary CreateOrUpdate
     * @request POST:/api/editor/handin/{handInId}
     */
    editorHandinCreate: (handInId: string, data: HandInCreateRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/editor/handin/${handInId}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EditorApi
     * @name EditorHandinV2Create
     * @summary CreateOrUpdateV2
     * @request POST:/api/editor/handin/{handInId}/v2
     */
    editorHandinV2Create: (handInId: string, data: HandInCreateRequestV2, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/editor/handin/${handInId}/v2`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EditorApi
     * @name EditorHandinResponseCreate
     * @summary SetResponse
     * @request POST:/api/editor/handin/{handInId}/response
     */
    editorHandinResponseCreate: (handInId: string, data: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/editor/handin/${handInId}/response`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EditorApi
     * @name EditorHandinFinishCreate
     * @summary Finish
     * @request POST:/api/editor/handin/{handInId}/finish
     */
    editorHandinFinishCreate: (handInId: string, query?: { danish?: boolean }, params: RequestParams = {}) =>
      this.request<FinishResult, any>({
        path: `/api/editor/handin/${handInId}/finish`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EditorApi
     * @name EditorHandinReopenCreate
     * @summary Reopen
     * @request POST:/api/editor/handin/{handInId}/reopen
     */
    editorHandinReopenCreate: (handInId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/editor/handin/${handInId}/reopen`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FileApi
     * @name EditorHandinFileUploadExtramaterialeImagedataCreate
     * @summary UploadExtramaterialeImagedata
     * @request POST:/api/editor/handin/{handInId}/file/upload/extramateriale/imagedata
     */
    editorHandinFileUploadExtramaterialeImagedataCreate: (
      handInId: string,
      data: UploadExtramaterialeImagedataData,
      params: RequestParams = {},
    ) =>
      this.request<AppendixFile, any>({
        path: `/api/editor/handin/${handInId}/file/upload/extramateriale/imagedata`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FileApi
     * @name EditorHandinFileUploadExtramaterialeFileCreate
     * @summary UploadExtramaterialeFile
     * @request POST:/api/editor/handin/{handInId}/file/upload/extramateriale/file
     */
    editorHandinFileUploadExtramaterialeFileCreate: (
      handInId: string,
      data: { File?: File },
      params: RequestParams = {},
    ) =>
      this.request<AppendixFile, any>({
        path: `/api/editor/handin/${handInId}/file/upload/extramateriale/file`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FileApi
     * @name EditorHandinFileDeleteExtramaterialeFileCreate
     * @summary DeleteExtramaterialeFile
     * @request POST:/api/editor/handin/{handInId}/file/delete/extramateriale/file
     */
    editorHandinFileDeleteExtramaterialeFileCreate: (handInId: string, data: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/editor/handin/${handInId}/file/delete/extramateriale/file`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags FileApi
     * @name EditorHandinFileDeleteExtramaterialeFileUnsafeCreate
     * @summary DeleteExtramaterialeFileUnsafe
     * @request POST:/api/editor/handin/{handInId}/file/delete/extramateriale/file/unsafe
     */
    editorHandinFileDeleteExtramaterialeFileUnsafeCreate: (
      handInId: string,
      data: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/editor/handin/${handInId}/file/delete/extramateriale/file/unsafe`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags FileApi
     * @name EditorHandinFileRenameExtramaterialeFileCreate
     * @summary RenameExtramaterialeFile
     * @request POST:/api/editor/handin/{handInId}/file/rename/extramateriale/file
     */
    editorHandinFileRenameExtramaterialeFileCreate: (
      handInId: string,
      data: RenameExtramateriale,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/editor/handin/${handInId}/file/rename/extramateriale/file`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags FileApi
     * @name EditorHandinFileDetail
     * @summary GetAppendix
     * @request GET:/api/editor/handin/{handInId}/file/{appendixFileId}
     */
    editorHandinFileDetail: (handInId: string, appendixFileId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/editor/handin/${handInId}/file/${appendixFileId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FileApi
     * @name EditorHandinFileAssignmentDetail
     * @summary GetFile
     * @request GET:/api/editor/handin/{handInId}/file/assignment/{assignmentFileId}
     */
    editorHandinFileAssignmentDetail: (handInId: string, assignmentFileId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/editor/handin/${handInId}/file/assignment/${assignmentFileId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FileApi
     * @name EditorHandinFileAssignmentInlineDetail
     * @summary GetFileInline
     * @request GET:/api/editor/handin/{handInId}/file/assignment/{assignmentFileId}/inline
     */
    editorHandinFileAssignmentInlineDetail: (handInId: string, assignmentFileId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/editor/handin/${handInId}/file/assignment/${assignmentFileId}/inline`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags HandInApi
     * @name EditorHandinserviceCreateCreate
     * @summary Create
     * @request POST:/api/editor/handinservice/create
     */
    editorHandinserviceCreateCreate: (params: RequestParams = {}) =>
      this.request<HandInModel, any>({
        path: `/api/editor/handinservice/create`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags HandInRepositoryApi
     * @name EditorHandinrepositoryDetail
     * @summary Get
     * @request GET:/api/editor/handinrepository/{handInId}
     */
    editorHandinrepositoryDetail: (handInId: string, params: RequestParams = {}) =>
      this.request<HandIn, any>({
        path: `/api/editor/handinrepository/${handInId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags HandInRepositoryApi
     * @name EditorHandinrepositoryUpdate
     * @summary UpdateEndDate
     * @request PUT:/api/editor/handinrepository/{handInId}
     */
    editorHandinrepositoryUpdate: (handInId: string, data: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/editor/handinrepository/${handInId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags HandInRepositoryApi
     * @name EditorHandinrepositoryTrygetDetail
     * @summary TryGet
     * @request GET:/api/editor/handinrepository/{handInId}/tryget
     */
    editorHandinrepositoryTrygetDetail: (handInId: string, params: RequestParams = {}) =>
      this.request<HandIn, any>({
        path: `/api/editor/handinrepository/${handInId}/tryget`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags LinksCollectionApi
     * @name EditorLinkscollectionCreate
     * @summary CreateCollection
     * @request POST:/api/editor/linkscollection/{linksCollectionId}
     */
    editorLinkscollectionCreate: (linksCollectionId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/editor/linkscollection/${linksCollectionId}`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags LinksCollectionApi
     * @name EditorLinkscollectionLinksCreate
     * @summary CreateLink
     * @request POST:/api/editor/linkscollection/{linksCollectionId}/links/{linkId}
     */
    editorLinkscollectionLinksCreate: (
      linksCollectionId: string,
      linkId: string,
      data: LinkCreateRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/editor/linkscollection/${linksCollectionId}/links/${linkId}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags LinksCollectionApi
     * @name EditorLinkscollectionLinksDelete
     * @summary DeleteLink
     * @request DELETE:/api/editor/linkscollection/{linksCollectionId}/links/{linkId}
     */
    editorLinkscollectionLinksDelete: (linkId: string, linksCollectionId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/editor/linkscollection/${linksCollectionId}/links/${linkId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags LinksCollectionApi
     * @name EditorLinkscollectionLinksDetail
     * @summary Get
     * @request GET:/api/editor/linkscollection/{linksCollectionId}/links/{linkId}
     */
    editorLinkscollectionLinksDetail: (linkId: string, linksCollectionId: string, params: RequestParams = {}) =>
      this.request<Link, any>({
        path: `/api/editor/linkscollection/${linksCollectionId}/links/${linkId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags LinksCollectionApi
     * @name EditorLinkscollectionLinksDetail2
     * @summary GetMany
     * @request GET:/api/editor/linkscollection/{linksCollectionId}/links
     * @originalName editorLinkscollectionLinksDetail
     * @duplicate
     */
    editorLinkscollectionLinksDetail2: (linksCollectionId: string, params: RequestParams = {}) =>
      this.request<Link[], any>({
        path: `/api/editor/linkscollection/${linksCollectionId}/links`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags NoteApi
     * @name EditorNotescollectionNotesDelete
     * @summary Delete
     * @request DELETE:/api/editor/notescollection/{notesCollectionId}/notes/{id}
     */
    editorNotescollectionNotesDelete: (id: string, notesCollectionId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/editor/notescollection/${notesCollectionId}/notes/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags NoteApi
     * @name EditorNotescollectionNotesDetail
     * @summary GetNote
     * @request GET:/api/editor/notescollection/{notesCollectionId}/notes/{id}
     */
    editorNotescollectionNotesDetail: (id: string, notesCollectionId: string, params: RequestParams = {}) =>
      this.request<Note, any>({
        path: `/api/editor/notescollection/${notesCollectionId}/notes/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags NoteApi
     * @name EditorNotescollectionNotesCreate
     * @summary AddNote
     * @request POST:/api/editor/notescollection/{notesCollectionId}/notes/{id}
     */
    editorNotescollectionNotesCreate: (
      notesCollectionId: string,
      id: string,
      data: { File?: File },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/editor/notescollection/${notesCollectionId}/notes/${id}`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags NoteApi
     * @name EditorNotescollectionNotesDownloadDetail
     * @summary Download
     * @request GET:/api/editor/notescollection/{notesCollectionId}/notes/{id}/download
     */
    editorNotescollectionNotesDownloadDetail: (id: string, notesCollectionId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/editor/notescollection/${notesCollectionId}/notes/${id}/download`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags NoteApi
     * @name EditorNotescollectionNotesDownloadInlineDetail
     * @summary DownloadInline
     * @request GET:/api/editor/notescollection/{notesCollectionId}/notes/{id}/download/inline
     */
    editorNotescollectionNotesDownloadInlineDetail: (
      id: string,
      notesCollectionId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/editor/notescollection/${notesCollectionId}/notes/${id}/download/inline`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags NoteApi
     * @name EditorNotescollectionNotesDetail2
     * @summary GetMany
     * @request GET:/api/editor/notescollection/{notesCollectionId}/notes
     * @originalName editorNotescollectionNotesDetail
     * @duplicate
     */
    editorNotescollectionNotesDetail2: (
      notesCollectionId: string,
      query?: { collectionId?: string },
      params: RequestParams = {},
    ) =>
      this.request<Note[], any>({
        path: `/api/editor/notescollection/${notesCollectionId}/notes`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags NoteApi
     * @name EditorNotescollectionCreate
     * @summary CreateOrUpdate
     * @request POST:/api/editor/notescollection/{notesCollectionId}
     */
    editorNotescollectionCreate: (notesCollectionId: string, data: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/editor/notescollection/${notesCollectionId}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags NoteApi
     * @name EditorNotescollectionDetail
     * @summary Get
     * @request GET:/api/editor/notescollection/{notesCollectionId}
     */
    editorNotescollectionDetail: (notesCollectionId: string, params: RequestParams = {}) =>
      this.request<NotesCollection, any>({
        path: `/api/editor/notescollection/${notesCollectionId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Print
     * @name EditorHandinPrintDetail
     * @summary Index
     * @request GET:/api/editor/handin/{handInId}/print
     */
    editorHandinPrintDetail: (handInId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/editor/handin/${handInId}/print`,
        method: "GET",
        ...params,
      }),
  };
}
