import { AppendixFile } from './Swagger'
import './BroadcastChannelStub'

class Message<T> {
    constructor(public content: T, public type: string) {
    }
}

export default class EditorBroadcastChannel {
    public static instance = new EditorBroadcastChannel();
    
    private readonly channel = new BroadcastChannel('ArcanicEditor');

    private static ExtraMaterialTabOpened = 'ExtraMaterialTabOpened';
    private static ExtraMaterialTabClosed = 'ExtraMaterialTabClosed';
    private static AppendixType = 'appendix';
    private static RemoveAppendixType = 'appendix-remove';
    private static UpdateAppendixType = 'appendix-update';
    private static ReplaceAppendixType = 'appendix-replace';
    constructor() {
        this.channel.onmessage = this.receiveMessage;
    }
    
    private receiveMessage = (ev: MessageEvent<any>) => {
        if (ev.data.type === EditorBroadcastChannel.AppendixType) {
            console.log(ev);
            this.onNewAppendix(ev.data.content);
        }

        if (ev.data.type === EditorBroadcastChannel.RemoveAppendixType) {
            console.log(ev);
            this.onRemoveAppendix(ev.data.content);
        }

        if (ev.data.type === EditorBroadcastChannel.UpdateAppendixType) {
            console.log(ev);
            this.onUpdateAppendix(ev.data.content);
        }

        if (ev.data.type === EditorBroadcastChannel.ReplaceAppendixType) {
            console.log(ev);
            this.onReplaceAppendix(ev.data.content);
        }

        if (ev.data.type === EditorBroadcastChannel.ExtraMaterialTabOpened) {
            console.log(ev);
            this.onExtraMaterialTabOpened();
        }

        if (ev.data.type === EditorBroadcastChannel.ExtraMaterialTabClosed) {
            console.log(ev);
            this.onExtraMaterialTabClosed();
        }
    }
    
    close = () => {
        this.channel.close();
    }

    onExtraMaterialTabOpened: () => void = () => { };
    onExtraMaterialTabClosed: () => void = () => { };
    
    onNewAppendix: (appendix: AppendixFile) => void = e => { };
    onRemoveAppendix: (appendix: AppendixFile) => void = e => { };
    onUpdateAppendix: (appendix: AppendixFile) => void = e => { };
    onReplaceAppendix: (data: { oldAppendix: AppendixFile, newAppendix: AppendixFile}) => void = e => { };
    
    postExtraMaterialTabOpened() {
        console.log(EditorBroadcastChannel.ExtraMaterialTabOpened);
        this.channel.postMessage(new Message('', EditorBroadcastChannel.ExtraMaterialTabOpened));
    }

    postExtraMaterialTabClosed() {
        console.log(EditorBroadcastChannel.ExtraMaterialTabClosed);
        this.channel.postMessage(new Message('', EditorBroadcastChannel.ExtraMaterialTabClosed));
    }
    
    postNewAppendix(appendix: AppendixFile) {
        console.log(appendix);
        this.channel.postMessage(new Message(appendix, EditorBroadcastChannel.AppendixType));
    }

    postRemoveAppendix(appendix: AppendixFile) {
        console.log(appendix);
        this.channel.postMessage(new Message(appendix, EditorBroadcastChannel.RemoveAppendixType));
    }

    postUpdateAppendix(appendix: AppendixFile) {
        console.log(appendix);
        this.channel.postMessage(new Message(appendix, EditorBroadcastChannel.UpdateAppendixType));
    }
    postReplaceAppendix(oldAppendix: AppendixFile, newAppendix: AppendixFile) {
        console.log(oldAppendix);
        console.log(newAppendix);
        this.channel.postMessage(new Message({ oldAppendix: oldAppendix, newAppendix: newAppendix,}, EditorBroadcastChannel.ReplaceAppendixType));
    }
}