import { DefaultTheme } from 'styled-components';

const Theme: DefaultTheme = {
    sizes: {
        s: '0.5rem',
        m: '1rem',
        l: '1.5rem',
    },
    colors: {
        main: '#006fe6',
        white: '#fff'
    },
};

export const KUTheme: DefaultTheme = {
    sizes: {...Theme.sizes},
    colors: {
        ...Theme.colors,
        main: '#901A1E'
    }
}

export default Theme;