import React from 'react';
import DocumentInfo from './DocumentInfo';
import AssignmentInfo from './AssignmentInfo';
import styled from 'styled-components';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilePopup from "../Components/FilePopup";
import {HandInModel} from "../Swagger";
import NotesInfo from "./NotesInfo";
import LinksInfo from "./LinksInfo";

interface IMenuProps {
    text: string;
    handInId: string;
    handIn: HandInModel;
    documentInfoGotoHeadingRequest: (e: number) => void;
    hasAssignmentFile: boolean;
    extraMaterialInfo: JSX.Element;
}

export enum AccordionState {
    None,
    DocumentInfo,
    Assignment,
    ExtraMaterial,
    Links,
    Notes
}

const SideMenuStyled = styled.div`
    width:300px;
    height:100%;
    overflow:auto;
    background-color:#F1F1F1;
    display:flex;
    flex-flow:column;
    padding: 2px;
`

function SideMenu(p: IMenuProps) {
    const [expanded, setExpanded] = useState(AccordionState.Assignment);
    const [assignmentUrl, setAssignmentUrl] = useState('');
    const [showAssignmentPopup, setShowAssignmentPopup] = useState(false);
    const [showFilePopup, setShowFilePopup] = useState(false);
    const [popupFileUri, setPopupFileUri] = useState("");
    
    function popupAssignment(s: string) {
        setAssignmentUrl(s);
        setShowAssignmentPopup(true);
    }

    const handleAccordionChange = (state: AccordionState) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? state : AccordionState.None);
    }
    const { t } = useTranslation(); 

    return (<SideMenuStyled>
        <Accordion classes={{ expanded:"AccordionExpanded" }} expanded={expanded === AccordionState.Assignment} onChange={handleAccordionChange(AccordionState.Assignment)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {t("assignment")}
            </AccordionSummary>
            <AccordionDetails>
                <AssignmentInfo assignmentFiles={p.handIn.assignmentFiles!} handInId={p.handInId} popupAssignment={popupAssignment}/>
            </AccordionDetails>
        </Accordion>

        <Accordion classes={{ expanded: "AccordionExpanded" }} expanded={expanded === AccordionState.Notes} onChange={handleAccordionChange(AccordionState.Notes)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {t("notes")}
            </AccordionSummary>
            <AccordionDetails>
                <NotesInfo notes={p.handIn.notes || []} notesCollectionId={p.handIn.notesCollectionId || ""} setPopupFileUri={setPopupFileUri} setShowFilePopup={setShowFilePopup} />
            </AccordionDetails>
        </Accordion>
            
        <Accordion classes={{ expanded: "AccordionExpanded" }} expanded={expanded === AccordionState.ExtraMaterial} onChange={handleAccordionChange(AccordionState.ExtraMaterial)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {t("extraMaterial")}
            </AccordionSummary>
            <AccordionDetails>
                {p.extraMaterialInfo}
            </AccordionDetails>
        </Accordion>

        <Accordion classes={{ expanded: "AccordionExpanded" }} expanded={expanded === AccordionState.DocumentInfo} onChange={handleAccordionChange(AccordionState.DocumentInfo)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {t("documentInfo")}
            </AccordionSummary>
            <AccordionDetails>
                <DocumentInfo handInId={p.handInId} text={p.text} onGotoHeadingRequest={p.documentInfoGotoHeadingRequest}/>
            </AccordionDetails>
        </Accordion>

        <Accordion classes={{ expanded: "AccordionExpanded" }} expanded={expanded === AccordionState.Links} onChange={handleAccordionChange(AccordionState.Links)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {t("links")}
            </AccordionSummary>
            <AccordionDetails>
                <LinksInfo links={p.handIn.links || []} showCalculator={p.handIn.settings?.useCalculator ?? false} />
            </AccordionDetails>
        </Accordion>
        
        {p.hasAssignmentFile && <FilePopup title={t("assignment")} fileUri={assignmentUrl} show={showAssignmentPopup} setShow={setShowAssignmentPopup} /> }
        <FilePopup title={""} fileUri={popupFileUri} show={showFilePopup} setShow={setShowFilePopup} />

        </SideMenuStyled>
    )
}

export default SideMenu;