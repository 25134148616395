import { Async } from "react-async";
import { useParams, useSearchParams } from "react-router-dom";
import { ApiClient } from "../Api";
import { HandInModel } from "../Swagger";
import { appendixUrl } from '../SideMenu/AppendicesLogic';
import { Container, ImageList, ImageListItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface MainContentProps {
    id: string;
    handIn: HandInModel;
}

function MainContent(p: MainContentProps) {
    const [searchParams] = useSearchParams();
    const { t } = useTranslation(); 
    const ckEditorFuncNum = searchParams.get('CKEditorFuncNum');

    function selectImage(url: string) {
        window.opener.CKEDITOR.tools.callFunction(ckEditorFuncNum, url);
        window.close();
    }

    return <Container sx={{margin: 'auto', display: 'flex', flexDirection: 'column'}}>
        <Typography sx={{margin: 'auto'}}>{t('ImageBrowserHelpText', [p.handIn.appendices!.length.toString()])}</Typography>
        <ImageList cols={2} rowHeight={256} sx={{ width: 800, height: 800, margin: 'auto' }}>
            {p.handIn.appendices!.map(e =>
                <ImageListItem key={e.id}>
                    <img onClick={_ => selectImage(appendixUrl(p.id, e))} src={appendixUrl(p.id, e)} alt='' style={{cursor: 'pointer'}} />
                </ImageListItem>
            )}
        </ImageList>
    </Container>
}

function ImageBrowserApp() {
    const { id } = useParams();
    const guid = id || "";

    const Load = async () => {
        const handInResponse = await ApiClient.instance.api.editorHandinDetail(guid);

        return { 'handIn': handInResponse.data };
    };

    return (
        <Async promiseFn={Load}>
            {({ data, error, isLoading }) => {
                if (isLoading) return "Loading..."
                if (error) return "Failed";
                if (data)
                    return (
                        <MainContent id={guid} handIn={data.handIn} />
                    )
            }}
        </Async>
    )
}

export default ImageBrowserApp;