import {useState} from 'react';
import {ApiClient} from '../Api'
import {AppendixFile} from '../Swagger';
import {useNavigate} from 'react-router-dom';
import {Button, Input, Alert, Grid, Box, Typography, Tooltip} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {allowDrawEdit, isImage, appendixUrl} from '../SideMenu/AppendicesLogic';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface IProps {
    appendices: AppendixFile[]
    addAppendix: (a: AppendixFile) => void;
    removeAppendix: (a: AppendixFile) => void;
    updateAppendix: (a: AppendixFile) => void;
    id: string;
}

interface IAppendixItemProps {
    appendix: AppendixFile;
    id: string;
    removeAppendix: (a: AppendixFile) => void;
    updateAppendix: (a: AppendixFile) => void;
}

const AppendixItem = (ps: IAppendixItemProps) => {
    const [newName, setNewName] = useState<string>(ps.appendix.name?.replace(/\.[^\.]*$/, "") ?? "");
    const [renaming, setRenaming] = useState(false);
    const [deletable, setDeletable] = useState(true);
    const navigate = useNavigate();
    const {t} = useTranslation();

    const deleteFile = (f: AppendixFile) => {
        if (f.id) {
            ApiClient.instance.api.editorHandinFileDeleteExtramaterialeFileCreate(ps.id, f.id).then(() => {
                ps.removeAppendix(f);
                setDeletable(true);
            }).catch(e => {
                setDeletable(e.status !== 412);
            });
        }
    }
    
    const saveName = () => {
        if (!newName) {
            throw new Error("Error");
        }

        let n = newName;
        let oldName = ps.appendix.name || "";
        let i = oldName.lastIndexOf(".");

        if (i !== -1) {
            n += oldName.substring(i);
        }

        ApiClient.instance.api.editorHandinFileRenameExtramaterialeFileCreate(ps.id, {
            appendixFileId: ps.appendix.id,
            name: n
        }).then(() => {
            ps.updateAppendix({
                id: ps.appendix.id,
                include: ps.appendix.include,
                name: n
            });
            setRenaming(false);
        });
    }

    return <Grid container columns={16} wrap={"nowrap"} spacing={4} alignItems={"center"}>
        {renaming &&
            <>
                <Grid item xs={8}>
                    <Input type="text" value={newName} onChange={(e) => setNewName(e.target.value)} autoFocus/>
                </Grid>

                <Grid item xs={4}>
                    {!newName && <Alert severity="error">{t("error_required")}</Alert>}
                </Grid>
                <Grid item xs={2}>
                    <Button variant="contained" onClick={() => saveName()}>{t("save")}</Button>
                </Grid>
                <Grid item xs={2}>
                    <Button variant="text" onClick={() => setRenaming(false)}>{t("cancel")}</Button>
                </Grid>
            </>
        }
        {!renaming &&
            <>
                <Grid item xs={10}>
                    {isImage(ps.appendix) &&
                        <div>
                            <img alt={ps.appendix.name || ""} style={{width: "175px"}} src={appendixUrl(ps.id, ps.appendix)}/>
                        </div>
                    }
                    <Typography noWrap component="span">{ps.appendix.name}</Typography>
                </Grid>

                <Grid item xs={2}>
                    {allowDrawEdit(ps.appendix) &&
                        <Button variant="text" onClick={() => { navigate("../drawing", {state: {file: ps.appendix}}); }}>{t("edit")}</Button>
                    }
                </Grid>

                <Grid item xs={2}>
                    <Button variant="text" onClick={() => { setRenaming(true) }}>{t("rename")}</Button>
                </Grid>
                <Grid item xs={2} >
                    <Button variant="text" onClick={() => deleteFile(ps.appendix)}>{t("delete")}</Button>
                    {!deletable &&
                        <Tooltip title={"Kan ikke slettes, da den er indsat i besvarelsen"}>
                            <HelpOutlineIcon fontSize="small" style={{color: 'red'}} />
                        </Tooltip>
                    }
                </Grid>
            </>
        }
    </Grid>
}

function Appendices(p: IProps) {
    const {i18n} = useTranslation();
   
    const appendixFiles = p.appendices.sort((a, b) => a.name && b.name ? a.name.localeCompare(b.name, i18n.language) : a.name ? -1 : b.name ? 1 : 0);
    
    return <Box>
        <Grid container direction={"column"}>
            {appendixFiles.map(e =>
                <Grid style={{borderBottom: "1px solid gray"}} sx={{p: 2}} item key={e.id}>
                    <AppendixItem appendix={e} removeAppendix={p.removeAppendix} updateAppendix={p.updateAppendix} id={p.id}/>
                </Grid>
            )}
        </Grid>
    </Box>;
}

export default Appendices;
