import { useEffect, useState } from 'react';
import Async from "react-async";
import { ApiClient } from '../Api'
import { useParams, useSearchParams } from "react-router-dom";
import { ThemeProvider } from 'styled-components';
import Theme from '../Components/Theme';
import { HandInAnalysisModel, HandInChange, HandInModel } from '../Swagger';
import LanguageSelector from '../Components/LanguageSelector';
import MuiThemeProvider from "../Components/MuiThemeProvider";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { Alert, Link, Paper } from "@mui/material";
import moment from 'moment';
import AveragedAbsoluteGraph from './AveragedAbsoluteGraph';

interface IProps {
    id: string;
    stats: HandInAnalysisModel;
    handIn: HandInModel;
}

function MainContent(p: IProps) {
    const [htmlComparison, setHtmlComparison] = useState('');
    const [selectedHandInChange, setSelectedHandInChange] = useState<HandInChange>();
    const [searchParams] = useSearchParams();
    const password = searchParams.get('password') ?? '';
    const [zoomed, setZoomed] = useState(false);
    const [zoomData, setZoomData] = useState<HandInChange[]>([]);
    const [theme] = useState(Theme);
    const { t } = useTranslation();
    const graphResolution = 120;

    useEffect(() => {
        document.title = t('revisionHistory');
    });

    const selectChange = async (e: HandInChange) => {
        const min = moment(e.occuredAt).subtract(graphResolution, "seconds");
        const max = moment(e.occuredAt).add(graphResolution, "seconds");

        const surroundingChanges = p.stats.changes!.filter(e => moment(e.occuredAt).isBetween(min, max));

        await loadData(e);

        setZoomData(surroundingChanges);
        setZoomed(true);
    }

    const loadData = async (e: HandInChange) => {
        const previousHandInDataId = p.stats.changes?.[p.stats.changes?.indexOf(e) - 1 ?? 0]?.handInDataId ?? e.handInDataId;

        const previousHandInData = await ApiClient.instance.api.editorHandinAnalysisHandindataDetail(p.handIn.id!, previousHandInDataId!, { headers: { 'Password': password } });
        const handInData = await ApiClient.instance.api.editorHandinAnalysisHandindataDetail(p.handIn.id!, e.handInDataId!, { headers: { 'Password': password } });

        // ApiClient tries to parse the result and fails, use fetch directly to avoid this
        const diffRequest = await fetch(`${process.env.REACT_APP_API_URI}/api/editor/comparehtml`,
            { body: JSON.stringify({ oldHtml: previousHandInData.data.content, newHtml: handInData.data.content }), headers: new Headers({'content-type': 'application/json'}), method: 'POST', });

        const diff = await diffRequest.text();
        setHtmlComparison(diff);
        setSelectedHandInChange(e);
    }

    const changes = p.stats.changes!.sort((a, b) => moment(a.occuredAt).unix() - moment(b.occuredAt).unix());
    const normal = changes;
    const warnings = changes.filter(e => e.suspicious);

    const displayChange = (e: HandInChange) => {
        const time = moment(e.occuredAt).format('HH:mm:ss');

        return `${time} ${e.deltaCount! === 0 ? t('noChanges') : `${e.deltaCount! > 0 ? '+' : ''}${t('characters', [e.deltaCount])}`}`;
    }

    return (
        <MuiThemeProvider>
            <ThemeProvider theme={theme}>
                <Box display="flex" flexDirection="column">
                    <AppBar>
                        <Toolbar style={{ backgroundColor: "white", color: "black" }}>
                            <img src="/digitaleksamen.logo.png" alt="logo" style={{ marginRight: "20px" }} />

                            <div style={{ marginLeft: "auto" }}>
                                <span style={{ marginRight: "20px" }}>{p.handIn.title}</span>
                                <span style={{ marginRight: "20px" }}>{p.handIn.studentName}</span>
                                <LanguageSelector />
                            </div>
                        </Toolbar>
                    </AppBar>

                    <Box>
                        <Toolbar />
                        <div style={{ position: 'fixed', width: '300px' }}>
                            <Box display="flex" flexDirection="column" sx={{ p: 2, boxShadow: 3 }} style={{ height: 'calc(100vh - 64px)', overflowX: 'scroll' }}>
                                <Box sx={{marginBottom: 2}}>
                                    <Typography variant={"h6"}>{t('suspiciousChanges')}</Typography>
                                    {warnings.map(e =>
                                        <Alert severity="warning" key={`${e.handInDataId}-warning`}>
                                            <Link component="button" variant="body2" onClick={() => selectChange(e)}>
                                                {displayChange(e)}
                                            </Link>
                                        </Alert>
                                    )}
                                </Box>
                                <Box>
                                    <Typography variant={"h6"}>{t('allChanges')}</Typography>
                                    {normal.map(e =>
                                        <Typography key={e.handInDataId}>
                                            <Link component="button" variant="body2" onClick={() => selectChange(e)}>
                                                {displayChange(e)}
                                            </Link>
                                        </Typography>
                                    )}
                                </Box>
                            </Box>

                        </div>
                        <Box sx={{ p: 3 }} display="flex">
                            <Paper sx={{ p: 1 }} style={{ marginLeft: 'auto', marginRight: 'auto', minWidth: '800px' }}>
                                <Typography variant="h6" noWrap component="div">{t('revisionHistory')}</Typography>

                                <Box style={{ width: '100%', minHeight: '300px' }}>
                                    <AveragedAbsoluteGraph handIn={p.handIn} stats={p.stats} resolution={graphResolution} dataPointClicked={e => loadData(e)} setZoomData={setZoomData} setZoomed={setZoomed} zoomed={zoomed} zoomData={zoomData} />
                                </Box>
                                <Box>
                                    {selectedHandInChange ? <Typography variant={"h6"}>{t('changeAtSpecificTime', [moment(selectedHandInChange.occuredAt!).format('HH:mm:ss')])}</Typography> :
                                        <Typography variant={"h4"}></Typography>}
                                    <Box style={{ maxWidth: '800px' }}>
                                        {htmlComparison ?
                                            <div dangerouslySetInnerHTML={{ __html: htmlComparison }}></div> :
                                            <Typography sx={{ fontStyle: 'italic' }}>{t('chooseChangeFromMenu')}</Typography>}
                                    </Box>
                                </Box>
                            </Paper>
                        </Box>
                    </Box>
                </Box>
            </ThemeProvider>
        </MuiThemeProvider>
    )
}

function StatsApp() {
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const password = searchParams.get('password') ?? '';
    const guid = id || "";

    const Load = async () => {
        const analysisResponse = searchParams.get("test") ? await ApiClient.instance.api.editorHandinAnalysisTestDetail(guid)
            : await ApiClient.instance.api.editorHandinAnalysisDetail(guid, { headers: { 'Password': password } });
        const handInResponse = await ApiClient.instance.api.editorHandinDetail(guid);

        return { 'stats': analysisResponse.data, 'handIn': handInResponse.data };
    };

    return (
        <Async promiseFn={Load}>
            {({ data, error, isLoading }) => {
                if (isLoading) return "Loading..."
                if (error) return "Failed";
                if (data)
                    return (
                        <MainContent id={guid} stats={data.stats} handIn={data.handIn} />
                    )
            }}
        </Async>
    )
}

export default StatsApp;
