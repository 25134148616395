import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import AssignmentFull from './AssignmentFull';
import Preview from './Preview';
import GlobalStyle from './GlobalStyle';
import ExtraMaterialApp from './ExtraMaterial/ExtraMaterialApp';
import HandInReceipt from "./HandInReceipt";
import HandInFailed from "./HandInFailed";
import NotesApp from "./Notes/NotesApp";
import NoteFull from "./Notes/NoteFull";
import StatsApp from "./Stats/StatsApp";
import ImageBrowserApp from './ImageBrowser/ImageBrowserApp';

ReactDOM.render(
  <React.StrictMode>
      <GlobalStyle/>
      <BrowserRouter>
          <Routes>
              <Route path="imagebrowser/:id" element={<ImageBrowserApp />}>
              </Route>
              <Route path="stats/:id" element={<StatsApp />}>
              </Route>
              <Route path="notes/:id" element={<NotesApp />}>
              </Route>              
              <Route path="handin/:id" element={<App />}>
              </Route>
              <Route path="handin/:id/receipt" element={<HandInReceipt />}>
              </Route>
              <Route path="handin/:id/retry" element={<HandInFailed />}>
              </Route>
              <Route path="assignment/:handInId/file/:id" element={<AssignmentFull />}>
              </Route>
              <Route path="notescollection/:id/note/:noteId" element={<NoteFull />}>
              </Route>
              <Route path="handin/:id/preview" element={<Preview />}>
              </Route>
              <Route path="handin/:id/extramaterial/*" element={<ExtraMaterialApp />}>
              </Route>
              <Route
                  path="*"
                  element={
                      <main style={{ padding: "1rem" }}>
                          <p>There's nothing here!</p>
                      </main>
                  }
              />
          </Routes>
          
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
