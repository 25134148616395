import { ApiClient } from "../Api";
import styled from "styled-components";
import { Button, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { AssignmentFile } from "../Swagger";

interface IHandinInfo {
    handInId: string;
    assignmentFiles: AssignmentFile[];
    popupAssignment: (s: string) => void;
}

interface IAssignmentDisplayInfo {
    handInId: string;
    file: AssignmentFile;
    popupAssignment: (s: string) => void;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  
  display: flex;
  flex-flow: column;
`

function AssignmentDisplay(p: IAssignmentDisplayInfo) {
    const { t } = useTranslation();

    const assignmentTabUrl = "/assignment/" + p.handInId + "/file/" + p.file.id;
    const assignmentUrl = ApiClient.baseUrl + "/api/editor/handin/" + p.handInId + "/file/assignment/" + p.file.id + "/inline";

    return <Container>
        <h4>{p.file.name}</h4>
        <Button target="_blank" href={assignmentTabUrl}>{t("openTab")}</Button>
        <Button onClick={() => p.popupAssignment(assignmentUrl)}>{t("showInPopup")}</Button>
        <embed style={{ flex: "1 0" }} src={assignmentUrl} />
    </Container>
}

function AssignmentInfo(p: IHandinInfo) {
    const { t } = useTranslation();

    return (
        <Container style={{ minHeight: 'min-height: 500px' }}>
            {p.assignmentFiles.map(e => <AssignmentDisplay handInId={p.handInId} file={e} popupAssignment={p.popupAssignment} />)}
            {p.assignmentFiles.length === 0 ?? <Typography fontStyle={"italic"} align={"center"} sx={{ mt: 3 }}>{t('noAssignmentFile')}</Typography>}
        </Container>)
}

export default AssignmentInfo;