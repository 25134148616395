import React from 'react';
import styled from 'styled-components'

const Content = styled.div`
  background: white;
  border: 1px black;
  padding: 0 1rem;
`

const Container = styled.div`
  display: flex;
  position: relative;
  flex: 1 0;
`

const Background = styled.div`
  background: rgba(0,0,0,.5);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`

function Overlay(params: { front: React.ReactNode, children: React.ReactNode, show: boolean }) {
    const show = params.show;

    return (
        <Container>
            {show &&
                <Background>
                    <Content>
                        {params.front}
                    </Content>
                </Background>
            }

            {params.children}
        </Container>
    )
}

export default Overlay;