import React, {useState} from 'react';
import styled from "styled-components";
import {Button, Link} from "@mui/material";
import {Link as LinkData} from "../Swagger";
import Popup from "../Components/Popup";
import {useTranslation} from "react-i18next";

interface IParams {
    links: LinkData[];
    showCalculator: boolean;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
`

function LinksInfo(p: IParams) {
    const [showCalculatorPopup, setShowCalculatorPopup] = useState(false);
    const { t } = useTranslation();


    return (
        <Container>
            {p.showCalculator && <div>
                <Button onClick={() => setShowCalculatorPopup(true)}>{t("calculator")}</Button>
            </div>}
            
            {p.links.map(link => <div key={link.id}>
                <Link target="_blank" rel="noreferrer" href={link.uri || ""}>{link.name}</Link>
            </div>)}

            {p.showCalculator && <Popup title={t("calculator")} show={showCalculatorPopup} setShow={setShowCalculatorPopup} width={"800px"}>
                {showCalculatorPopup && <iframe title={t("calculator")} src="https://www.calculator.net/" width={"100%"} height={"100%"}></iframe>}
            </Popup> }
        </Container>)
}

export default LinksInfo;