import {ApiClient} from "../Api";
import styled from "styled-components";
import {Button, Typography} from "@mui/material";
import {useTranslation} from 'react-i18next';
import {Note} from "../Swagger";

interface INotesInfoParams {
    notesCollectionId: string;
    notes: Note[];
    setPopupFileUri: (b: string) => void;
    setShowFilePopup: (b: boolean) => void;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
`

function NotesInfo(p: INotesInfoParams) {
    const {t} = useTranslation();
    
    function getNotesUrl(id: string) {
        return ApiClient.baseUrl + "/api/editor/notescollection/" + p.notesCollectionId + "/notes/" + id + "/download/inline"
    }

    function getNotesTabUrl(id: string) {
        return `/notescollection/${p.notesCollectionId}/note/${id}`;
    }

    function showFile(uri: string) {
        p.setPopupFileUri(uri);
        p.setShowFilePopup(true);
    }
    
    return (
        <Container>
            {p.notes.map(note => <div key={note.id}>
                <Typography>{note.name}</Typography>
                <Button target="_blank" href={getNotesTabUrl(note.id || "")}>{t("openTab")}</Button>
                <Button onClick={() => showFile(getNotesUrl(note.id || ""))}>{t("showInPopup")}</Button>
            </div>)}
            
        </Container>)
}

export default NotesInfo;