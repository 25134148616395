import Popup from "./Popup";

interface IFilePopupParams {
    fileUri: string;
    show: boolean;
    setShow: (b: boolean) => void;
    title: string;
}

function FilePopup(p: IFilePopupParams) {
    const width = "800px"

    return (
        <Popup show={p.show} setShow={p.setShow} title={p.title} width={width}>
            {p.fileUri && <embed style={{width: '100%', height: '100%'}} src={p.fileUri}/>}
        </Popup>
    )
}

export default FilePopup;