import React, { useEffect } from 'react';
import {ApiClient} from '../Api'
import Webcam from 'react-webcam';
import {AppendixFile, HttpResponse} from '../Swagger';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';

export enum WebCameraViewState {
    Searching,
    NoCamera,
    NoPermission,
    SelectCamera,
    UseCamera
}

interface IProps {
    addAppendix: (a: AppendixFile) => void;
    incrementAppendixCounter: () => void;
    nextAppendixCount: () => number;
    id: string;
}

function WebCamera(p: IProps) {
    const [viewState, setViewState] = React.useState(WebCameraViewState.Searching);
    const [viewThumbCams, setViewThumbCams] = React.useState(false);
    const [cameras, setCameras] = React.useState<MediaDeviceInfo[]>([]);
    const [camera, setCamera] = React.useState<MediaDeviceInfo>();
    const { t } = useTranslation();
    let navigate = useNavigate();
    
    const addAppendix = (data: any) => {
        // TODO Fejl h�ndtering.
        ApiClient.instance.api.editorHandinFileUploadExtramaterialeImagedataCreate(p.id,{
            imageData: data,
            name: "Appendix " + p.nextAppendixCount() + ".png"
        }).then((response: HttpResponse<AppendixFile, any>) => {
            p.incrementAppendixCounter();
            p.addAppendix(response.data);
            navigate("../appendices");
        });
    }

    const webcamRef = React.useRef(null);
    const [imgSrc, setImgSrc] = React.useState(undefined);

    const capture = React.useCallback(() => {
        if (webcamRef && webcamRef.current) {
            // @ts-ignore: Object is possibly 'null'.
            const imageSrc = webcamRef.current.getScreenshot();
            setImgSrc(imageSrc);
        }
    }, [webcamRef, setImgSrc]);

    // Dette for at undg� evigt loop render.
    useEffect(() => { 
        let r = async () => {
            const devices = await navigator.mediaDevices.enumerateDevices();
            let c: MediaDeviceInfo[] = [];
            devices.forEach(x => {
                if (x.kind === "videoinput") {
                    c.push(x);
                }
            });
            
            setCameras(c);

            navigator.mediaDevices.getUserMedia({video: true, audio: false}).then(e => {
                if (c.length === 1) {
                    setCamera(c[0]);
                    setViewState(WebCameraViewState.UseCamera);
                } else if (c.length > 1) {
                    setViewState(WebCameraViewState.SelectCamera);
                } else if (c.length === 0) {
                    setViewState(WebCameraViewState.NoCamera);
                }
            }).catch(e => {
                if (c.length > 0) {
                    setViewState(WebCameraViewState.NoPermission);
                } else {
                    setViewState(WebCameraViewState.NoCamera);
                }
            });
        }
        r();
    }, []);

    const renderSwitch = (viewState: WebCameraViewState) => {
        switch (viewState) {
            case WebCameraViewState.UseCamera:
                return <div>
                    {cameras.length > 1 &&
                        <div style={{ textAlign: "right" }}>
                            <Button variant="text" onClick={() => setViewState(WebCameraViewState.SelectCamera)}>{t("pickWebCamera")}</Button>
                        </div>
                    }
                    <div style={{ display: imgSrc ? "none":"block" }}>
                        <div>{t("live")}</div>
                        <Webcam screenshotFormat="image/png" audio={false} ref={webcamRef} videoConstraints={{ deviceId: camera?.deviceId }} />
                    </div>
                    <div style={{ display: !imgSrc ? "none" : "block" }}>
                        <div>{t("screenshot")}</div>
                        {imgSrc && <img src={imgSrc} alt="Web camera shot" />}
                    </div>                    
                    <div>
                        {!imgSrc && <Button variant="contained"  onClick={capture}>{t("takePicture")}</Button>}
                        {imgSrc && <Button variant="text" onClick={() => setImgSrc(undefined)}>{t("discardPicture")}</Button>}
                        {imgSrc && <Button variant="contained" onClick={() => addAppendix(imgSrc)}>{t("addToExtraMaterial")}</Button>}
                    </div>
                </div>
            case WebCameraViewState.SelectCamera:
                let size = 15
                // Lister camera med et fortl�bende index for at undg� at sp�rge om adgang til samtlige cameraer.
                // Brugeren kan v�lge at tykke p� Vis billeder knappen g�r oven st�ende.
                return <div>
                    <h1>{t("pickWebCam")}</h1>
                    
                    {cameras.map((x, index) =>

                        viewThumbCams ?
                            <div><Webcam width={16 * size} height={9 * size} onClick={() => { setCamera(x); setViewState(WebCameraViewState.UseCamera) }}
                                key={x.deviceId} videoConstraints={{ deviceId: x.deviceId }} /></div>
                            : <div style={{marginBottom: "10px"}}><Button variant="outlined" onClick={() => { setCamera(x); setViewState(WebCameraViewState.UseCamera) }}>{x.label}</Button></div>

                    
                    )}
                    <br /><br />
                    <Button onClick={() => setViewThumbCams(!viewThumbCams)}>
                        {viewThumbCams ? t("hideWebCameraThumbs") : t("showWebCameraThumbs")}
                    
                    </Button>

                </div>
            case WebCameraViewState.NoCamera:
                return <div>
                    <Typography>{t("noWebCamera")}</Typography>
                </div>
            case WebCameraViewState.NoPermission:
                return <div>
                    <Typography>
                        {t("noWebCamPermission")}
                    </Typography>
                </div>
            case WebCameraViewState.Searching:
                return <div>
                    <Typography>
                        {t("seekingWebCamera")}
                    </Typography>
                </div>
        }
    }

    return (<div>{renderSwitch(viewState)}</div>);
}
export default WebCamera;