import { useTranslation } from "react-i18next";
import {Button} from "@mui/material";
import {useSearchParams} from "react-router-dom";

const LanguageSelector = () => {
    const { t, i18n } = useTranslation();
    const [params, setParams] = useSearchParams();
    const languageKey = 'editorLagnuage';

    async function setLanguage(l: string) {
        localStorage.setItem(languageKey, l);

        await i18n.changeLanguage(l);
    }

    async function toggleLanguage() {
        const current = i18n.language;
        const query = params.get('lang');

        if (query) {
            setParams('');
        }
        
        if (current === "da") {
            await setLanguage("en");
        } else {
            await setLanguage("da");
        }
    }
    
    return (
        <Button variant="text" color="inherit" onClick={toggleLanguage}>
            {t("language")}
        </Button>
    );
};


export default LanguageSelector;