import React from 'react';
import { useParams } from 'react-router-dom';
import {ApiClient} from "./Api";

function Preview(){
    let { id } = useParams();
    const url = ApiClient.baseUrl + "/api/editor/handin/" + id + "/preview";

    return (
        <embed style={{width: "100vw", height: "100vh"}} src={url}/>
    )
}

export default Preview;