import React from 'react';
import {createTheme, ThemeProvider} from "@mui/material/styles";

const MuiTheme = createTheme({
    palette: {
        primary: {
            main: '#337ab7'
        }
    }
});

function MuiThemeProvider(p: { children: React.ReactNode }) {
    return <ThemeProvider theme={MuiTheme}>
        {p.children}
    </ThemeProvider>
}

export default MuiThemeProvider;