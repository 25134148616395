import _ from "lodash";
import {HandInChange} from "../Swagger";
import moment from "moment/moment";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis} from "recharts";
import { ValueType, NameType } from "recharts/types/component/DefaultTooltipContent";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
    changes: HandInChange[];
    yAxisMax: number;
    dataPointClicked: (e: HandInChange) => void;
}

export interface GraphData {
    count: number;
    offset: number;
    original: HandInChange[];
}

function CustomTooltip(p: { data: GraphData; }) {
    const { t } = useTranslation();

    return <Box sx={{ border: 1, padding: 1, borderRadius: '5px', boxShadow: 1, borderColor: 'gray', backgroundColor: 'white' }}>
        <Typography>{t('noOfCharacters', [p.data.count])}</Typography>
    </Box>
}

function AbsoluteGraph(p: IProps) {
    const [clickedDataPoint, setClickedDataPoint] = useState<HandInChange>();

    const moments = p.changes.map(e => moment(e.occuredAt));
    const firstMoment = moment(p.changes[0].occuredAt);

    const startTime = _.minBy(moments, e => e.unix()) || firstMoment;
    const endTime = _.maxBy(moments, e => e.unix()) || firstMoment;
    const duration = endTime.diff(startTime, 'minutes', true);

    const graphData = p.changes.map(e => {
        const offset = moment(e.occuredAt).diff(startTime, "minutes", true);
        return { count: e.totalCount!, offset: offset, original: e }
    });

    const clickHandler = (i: number) => {
        const item = graphData[i];
        setClickedDataPoint(item.original);
        p.dataPointClicked(item.original);
    }

    function customTooltip(p: TooltipProps<ValueType, NameType>) {
        if (p.payload && p.payload[0]) return <CustomTooltip data={p.payload[0].payload}/>

        return null;
    }

    const darkRed = '#990000'

    const CustomizedDot = (props: any) => {
        const {cx, cy, stroke, payload} = props;

        return <circle r="3" fill="#fff" cx={cx} cy={cy} stroke={payload.original === clickedDataPoint ? darkRed : stroke}></circle>
    }

    const CustomizedActiveDot = (props: any) => {
        const {cx, cy, payload, fill} = props;

        const color = payload.original === clickedDataPoint ? darkRed : fill;

        return <circle r="3" fill={color} cx={cx} cy={cy} stroke={color}></circle>
    }

    function createXAxisLabel(e : number) {
        const label = startTime.clone().add(e, 'minutes').format('HH:mm:ss');
        console.log(label);
        return label;
    }

    return (<ResponsiveContainer height="99%" aspect={3}>
        <LineChart onClick={e => clickHandler(e.activeTooltipIndex ?? 0)}
            data={graphData}>
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis type="number" dataKey="offset" interval={'preserveStartEnd'} domain={[0, duration]} tickFormatter={createXAxisLabel} />
            <YAxis type="number" domain={[0, p.yAxisMax]} dataKey="count" interval={'preserveStartEnd'}/>
            <Tooltip content={customTooltip} />
            <Line dataKey="count" dot={<CustomizedDot />} activeDot={<CustomizedActiveDot/>} isAnimationActive={false} />
        </LineChart>
    </ResponsiveContainer>);
}

export default AbsoluteGraph;