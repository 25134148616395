import React, {useRef} from 'react';
import {Box, Button, Paper, Typography, Toolbar, AppBar} from "@mui/material";
import {useTranslation} from 'react-i18next';
import Draggable from 'react-draggable';

interface IPopupParams {
    show: boolean;
    setShow: (b: boolean) => void;
    title: string;
    children: React.ReactNode;
    width: string;
}

function Popup(p: IPopupParams) {
    const {t} = useTranslation();
    const nodeRef = useRef(null);

    return (
        <>
            {
                p.show &&
                <div style={{position: 'fixed', zIndex: 1, width: 0, height: 0}}>
                    <Draggable handle=".handle" nodeRef={nodeRef}>
                        <Box style={{width: p.width}} ref={nodeRef}>
                            <Paper>
                                <AppBar>
                                    <Toolbar className={"handle"} style={{cursor: 'grab'}}>
                                        <Typography>{p.title}</Typography>
                                        <Button style={{marginLeft: 'auto'}} color={"inherit"}
                                                onClick={() => p.setShow(false)}>{t("close")}</Button>
                                    </Toolbar>
                                </AppBar>

                                <Box sx={{p: 3}} style={{height: '800px'}}>
                                    <Toolbar/>
                                    {p.show && <>{p.children}</>}
                                </Box>

                                <Box style={{marginTop: '40px'}}>
                                    <Toolbar>
                                        <Button onClick={() => p.setShow(false)}>{t("close")}</Button>
                                    </Toolbar>
                                </Box>
                            </Paper>
                        </Box>
                    </Draggable>
                </div>
            }</>
    )
}

export default Popup;