import React from 'react';
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import Content from "./Components/Content";
import {FinishResultContent} from "./Swagger";
import {Box} from "@mui/material";

function HandInReceipt() {
    const {t} = useTranslation();
    const location = useLocation();
    const state = location.state as FinishResultContent;

    return (
        <Box sx={{p: 3}}>
            {!state && <>
                <p>{t("receipt1")}</p>
                <p>{t("receipt2")}</p>
            </>}
            {state && <Content contentType={state.type || ""} content={state.content || ""}/>}
        </Box>
    )
}

export default HandInReceipt;