import {useEffect, useState} from 'react';
import HandIn from './HandIn';
import Async from "react-async";
import { ApiClient } from './Api'
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import { SaveStatus } from './Components/SaveMonitor';
import SaveStatusOverlay from './SaveStatusOverlay';
import { ThemeProvider } from 'styled-components';
import Theme from './Components/Theme';
import { HandInModel as HandInData } from './Swagger';
import "./i18n";
import LanguageSelector from './Components/LanguageSelector';
import MuiThemeProvider from "./Components/MuiThemeProvider";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { useBeforeunload } from 'react-beforeunload';
import EditorCountdown from "./EditorCountdown";
import {useTranslation} from "react-i18next";

interface IProps {
    id: string;
    handin: HandInData;
    debug: boolean;
}

function MainContent(p: IProps) {
    const [theme] = useState(Theme);
    const [status, setStatus] = useState(SaveStatus.Saved);

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const showSaveStatus = status !== SaveStatus.Saved && status !== SaveStatus.Saving;
    
    useBeforeunload(e => {
        if (showSaveStatus) e.preventDefault()
    });

    function goToReceipt() {
        navigate('receipt');
    }
    
    function finishHandIn() {
        ApiClient.instance.api.editorHandinFinishCreate(p.id, { danish: i18n.language === "da" })
            .then(e => {
                if (e.data.success) return navigate("receipt", {state: e.data.content});
                else return navigate("retry", {state: e.data.content});
            })
            .catch(() => {
                return navigate("retry");
            });
    }
    
    useEffect(() => {
        document.title = t('mainTitle');
    });
    
    useEffect(() => {
        if (p.handin.finished) {
            goToReceipt();
        }
    })
    
    return (
        <MuiThemeProvider>
            <ThemeProvider theme={theme}>
                <Box display="flex" flexDirection="column" sx={{ p: 3 }}>
                    <AppBar>
                        <Toolbar style={{ backgroundColor: "white", color: "black" }}>
                            <img src="/digitaleksamen.logo.png" alt="logo" style={{marginRight: "20px"}} />

                            <Typography variant="h3" noWrap component="div">{p.handin.title || ""}</Typography>
                            <div style={{ marginLeft: "auto" }}>
                                <span style={{ marginRight: "20px" }}>{p.handin.studentName || ""}</span>
                                <span style={{ marginRight: "20px" }}><EditorCountdown id={p.id} endDate={p.handin.end} finished={finishHandIn} /></span>
                                {/*<Button onClick={finishHandIn}>TIMES UP</Button>*/}
                                <LanguageSelector />                                
                            </div>
                        </Toolbar>
                    </AppBar>

                    <Box sx={{ margin: 'auto' }}>
                        <Toolbar />
                        <SaveStatusOverlay show={showSaveStatus} status={status}>
                            <HandIn onSaveStatusChanged={setStatus} id={p.id} handIn={p.handin} saveStatus={status} />
                        </SaveStatusOverlay>
                    </Box>
                </Box>

            </ThemeProvider>
        </MuiThemeProvider>
    )
}

function App() {
    const { id } = useParams();
    const guid = id || "";
    const [searchParams] = useSearchParams();
    const isDebug = searchParams.has("debug");

    const Load = () => {
        return ApiClient.instance.api.editorHandinDetail(guid).then(handIn => {
            return { 'handIn': handIn.data, 'id': guid }
        });
    };
    
    return (
        <Async promiseFn={Load}>
            {({ data, error, isLoading }) => {
                if (isLoading) return "Loading..."
                if (error) return "Failed";
                if (data)
                    return (
                        <MainContent debug={isDebug} id={guid} handin={data.handIn} />
                    )
            }}
        </Async>
    )
}

export default App;
