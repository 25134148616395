import {Button, Typography} from '@mui/material';
import React, { useRef } from 'react'
import {useTranslation} from "react-i18next";

interface IFileUploaderProps {
    onFileSelect: (f: File) => void;
    allowedFileTypes?:Array<string>
    disabled: boolean;
}

function FileInput(p: React.PropsWithChildren<IFileUploaderProps>) {
    const fileInput = useRef<HTMLInputElement>(null)
    const {t} = useTranslation();

    const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length === 1) {
            const f = e.target.files[0];
            p.onFileSelect(f);
        }
    };
    const click = () => {
        if (fileInput.current) {
            let f: HTMLInputElement = fileInput.current;
            f.click()
        }
    };

    const accept = p.allowedFileTypes?.join(",");

    return (<>
        <input type="file" onChange={handleFileInput} ref={fileInput} style={{ display: "None" }} accept={accept} />
        <Button variant="contained" disabled={p.disabled} onClick={click}>{p.children}</Button>
        {p.disabled && <Typography sx={{pl: 3}} fontStyle={"italic"} >{t("fileuploaddisabled")}</Typography>}
        </>);
}

export default FileInput;