import React, {useEffect, useState} from 'react';
import Countdown from "react-countdown";
import {useTranslation} from "react-i18next";
import {ApiClient} from "./Api";

interface ICountdownRenderer {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    completed: boolean;
}

function EditorCountdown(p: {endDate: string | undefined, finished: () => void, id: string}) {
    const { t } = useTranslation();
    const [date, setDate] = useState(p.endDate);

    const renderer = (p: ICountdownRenderer) => {
        if (p.completed) {
            return <span>{t("testOver")}</span>
        }

        if (p.days) {
            return <span>{p.days}:{p.hours.toString().padStart(2, "0")}:{p.minutes.toString().padStart(2, "0")}:{p.seconds.toString().padStart(2, "0")}</span>
        }
        
        return <span>{p.hours.toString().padStart(2, "0")}:{p.minutes.toString().padStart(2, "0")}:{p.seconds.toString().padStart(2, "0")}</span>
    };

    function onComplete() {
        p.finished();
    }

    useEffect(() => {
        const timer = setInterval(() => {
            return ApiClient.instance.api.editorHandinDetail(p.id).then(handIn => {
                setDate(handIn.data.end);
            });
        }, 1000 * 60 * 5);

        return () => {
            clearInterval(timer);
        }
    });
    
    return (
        <Countdown date={date} renderer={renderer} onComplete={onComplete} />
    )
}

export default EditorCountdown;