import { useParams } from 'react-router-dom';
import { ApiClient } from './Api';
import styled from "styled-components";

const Embed = styled.embed`
  width: 100vw; 
  height: 99vh;
`

function AssignmentFull(){
    let { id, handInId } = useParams();
    
    const assignmentUrl = ApiClient.baseUrl + "/api/editor/handin/" + handInId + "/file/assignment/" + id + "/inline"
    return (
        <Embed src={assignmentUrl}/>
    )
}

export default AssignmentFull;