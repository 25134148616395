import { ApiClient } from '../Api'
import { Outlet, Route, Routes, useParams, useNavigate } from "react-router-dom";
import { ThemeProvider } from 'styled-components';
import Theme from '../Components/Theme';
import { AppendixFile, HandInModel } from '../Swagger';
import WebCamera from './WebCamera';
import Appendices from './Appendices';
import FreeDrawing from './FreeDrawing';
import "../i18n";
import LanguageSelector from "../Components/LanguageSelector";
import { useEffect, useState } from "react";
import EditorBroadcastChannel from "../EditorBroadcastChannel";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MuiThemeProvider from "../Components/MuiThemeProvider";
import { useTranslation } from 'react-i18next';
import { useBeforeunload } from "react-beforeunload";
import EditorCountdown from "../EditorCountdown";

interface IProps {
    id: string;
    handin: HandInModel;
}

function App(p: IProps) {
    const [appendices, setAppendices] = useState(p.handin?.appendices ?? []);

    function nextAppendixCount(): number {
        var appendixKey = "appendix";
        var tempValue = localStorage.getItem(appendixKey);
        return (tempValue != null ? parseInt(tempValue) : 0) + 1;
    }

    function incrementAppendixCounter() {
        var appendixKey = "appendix";
        var tempValue = localStorage.getItem(appendixKey);
        var value = (tempValue != null ? parseInt(tempValue) : 0) + 1;
        localStorage.setItem(appendixKey, value.toString());
    }

    function addAppendix(appendix: AppendixFile) {
        EditorBroadcastChannel.instance.postNewAppendix(appendix);
        setAppendices(old => [...old, appendix]);
    }

    function removeAppendix(appendix: AppendixFile) {
        EditorBroadcastChannel.instance.postRemoveAppendix(appendix);
        setAppendices(old => old.filter(x => appendix.id !== x.id));
    }

    function replaceAppendix(oldAppendix: AppendixFile, newAppendix: AppendixFile) {
        if (!oldAppendix.id) throw new Error("invalid id");
        
        EditorBroadcastChannel.instance.postReplaceAppendix(oldAppendix, newAppendix);
        setAppendices(old => [...old, newAppendix]);
        ApiClient.instance.api.editorHandinFileDeleteExtramaterialeFileUnsafeCreate(p.id, oldAppendix.id).then(() => {
            removeAppendix(oldAppendix);
        });
    }

    function updateAppendix(appendix: AppendixFile) {
        setAppendices(old => [...old.filter(x => appendix.id !== x.id), appendix]);
        EditorBroadcastChannel.instance.postUpdateAppendix(appendix);
    }

    return <Routes>
        <Route path="/" element={<Layout id={p.id} handin={p.handin} />}>
            <Route path="appendices"
                element={<Appendices appendices={appendices} addAppendix={addAppendix} removeAppendix={removeAppendix} updateAppendix={updateAppendix} id={p.id} />} />
            <Route path="camera" element={<WebCamera addAppendix={addAppendix} id={p.id} nextAppendixCount={nextAppendixCount} incrementAppendixCounter={incrementAppendixCounter} />} />
            <Route path="drawing" element={<FreeDrawing id={p.id} addAppendix={addAppendix} replaceAppendix={replaceAppendix} nextAppendixCount={nextAppendixCount} incrementAppendixCounter={incrementAppendixCounter} />} />
        </Route>
    </Routes>
}

const drawerWidth = 240;

function Layout(p: IProps) {
    const { t } = useTranslation();
    let navigate = useNavigate();

    function goToReceipt() {
        navigate('/handin/'+ p.id + '/receipt');
    }
    
    return <MuiThemeProvider>
        <ThemeProvider theme={Theme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Toolbar style={{ backgroundColor: "white", color: "black" }}>
                        <Typography variant="h3" noWrap component="div">{t("extraMaterial")}</Typography>
                        <div style={{ marginLeft: "auto" }}>
                            <span style={{ marginRight: "20px" }}><EditorCountdown endDate={p.handin.end} id={p.id} finished={goToReceipt} /></span>
                            <LanguageSelector />
                        </div>
                    </Toolbar>
                </AppBar>
                <Drawer sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }} variant="permanent" anchor="left">
                    <Toolbar />
                    <List>
                        <ListItem key={1} button onClick={() => navigate("appendices")}>
                            <ListItemText primary={t("extraMaterials")} secondary={t("allAddExtraMaterials")} />
                        </ListItem>

                        {p.handin.settings?.useWebcam &&
                        <ListItem key={2} button onClick={() => navigate("camera")}>
                            <ListItemText primary={t("webCamera")} secondary={t("webCameraGuide")} />
                        </ListItem>}

                        {p.handin.settings?.useDrawing &&
                        <ListItem key={3} button onClick={() => navigate("drawing")}>
                            <ListItemText primary={t("drawing")} secondary={t("drawingGuide")} />
                        </ListItem>}
                    </List>
                </Drawer>

                <Box sx={{ p: 3 }} style={{width: "50%"}}>
                    <Toolbar />
                    <Outlet />
                </Box>
            </Box>
        </ThemeProvider>
    </MuiThemeProvider>
}

function Failed() {
    return <div>Failed</div>
}

function Loading() {
    return <div>Loading</div>
}

function ExtraMaterialApp() {
    const { id } = useParams();
    const guid = id || "";
    const [handin, setHandin] = useState<HandInModel>();
    const [failed, setFailed] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('extraMaterial');
    });
    
    useEffect(() => {
        ApiClient.instance.api.editorHandinDetail(guid).then(handIn => {
            setHandin(handIn.data);
        }).catch(() => {
            setFailed(true);
        });
    }, [guid]);

    useEffect(() => {
        EditorBroadcastChannel.instance.postExtraMaterialTabOpened();
    });

    useBeforeunload(e => {
        EditorBroadcastChannel.instance.postExtraMaterialTabClosed();
    });

    return handin ?
        <App id={guid} handin={handin} />
        : failed ? <Failed /> : <Loading />
}

export default ExtraMaterialApp;