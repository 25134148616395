import { ApiClient } from "../Api";
import { AppendixFile } from "../Swagger";

export function allowDrawEdit(f: AppendixFile): boolean{
    return f.name?.endsWith(".png") || false;
}

// Dette bruger file extensionen til at finde ud af om et ekstra materiale er et billed.
// s� i praksis kan den have b�de falske negativer og positiver.
export function isImage(a: AppendixFile): boolean{
    return (a.name && /\.(?:png|gif|jpeg|jpg|bmp)$/.test(a.name)) || false;
}

// Dette bruger file extensionen til at finde ud af om et ekstra materiale er et billed.
// s� i praksis kan den have b�de falske negativer og positiver.
export function appendixUrl(handInId: string, a: AppendixFile): string {
    return ApiClient.baseUrl + "/api/editor/handin/" + handInId + "/file/" + a.id;
}