import React from 'react';
import Overlay from './Components/Overlay';
import { SaveStatus } from './Components/SaveMonitor';

const Saved = () => <p>Gemt!</p>
const Saving = () => <p>Gemmer, vent venligst</p>
const Failing = () => <p>Der er problemer med din forbindelse, vi forsøger at gemme, vent venligst</p>
const Error = () => <p>Der skete en fejl, genindlæs siden og prøv igen</p>

function SaveStatusOverlay(params: { status: SaveStatus, show: boolean, children: React.ReactNode }) {
    const frontContent = () => {
        switch (params.status) {
            case SaveStatus.Saved:
                return Saved()
            case SaveStatus.Saving:
                return Saving()
            case SaveStatus.Delayed:
                return Saving()
            case SaveStatus.Failing:
                return Failing()
            default:
                return Error()
        }
    }
    
    return (
        <Overlay front={frontContent()} show={params.show}>
            {params.children}
        </Overlay>

    )
}

export default SaveStatusOverlay;