import {HttpResponse} from "../Swagger";

export enum SaveStatus {
    Saved,
    Saving,
    Delayed,
    Failing,
    Error
}

export class SaveMonitor {
    constructor(private statusFn: (e: SaveStatus) => void) {
    }

    ensureSave<T, U>(saveFn: () => Promise<HttpResponse<T, U>>, savingStatusDelay: number = 500): Promise<HttpResponse<T, U>> {
        this.statusFn(SaveStatus.Saving);
        
        const setSavingPromise = this.timedPromise(() => this.statusFn(SaveStatus.Delayed), savingStatusDelay);

        const wrappedSaveFnFactory = () => this.wrapSwaggerPromise(saveFn());

        const firstTryPromise = wrappedSaveFnFactory().finally(() => {
            // Cancel setSavingPromise if anything else happened before it finished
            setSavingPromise.cancel()
        });

        return firstTryPromise
                .catch((reason) => {
                    this.statusFn(SaveStatus.Failing);

                    // Retry once without delay, in case of a random error that won't happen again
                    return this.ensureSaveInternal(wrappedSaveFnFactory).then(this.saved);
                }).then((e) => this.saved(e));
    }

    private ensureSaveInternal<T>(saveFn: () => Promise<T>): Promise<T> {
        return new Promise((resolve, reject) => {
            return saveFn()
                .then(e => resolve(e))
                .catch(() => {
                    return this.delay(this.random(5000, 15000))
                        .then(() => this.ensureSaveInternal(saveFn))
                        .then(resolve);
                });
        });
    }

    private saved = (e: any) => {
        this.statusFn(SaveStatus.Saved);
        return e;
    }
    
    private wrapSwaggerPromise<T, U>(promise: Promise<HttpResponse<T, U>>) {
        return new Promise<HttpResponse<T, U>>((resolve, reject) => {
            promise
                .then(e => {
                    if (e.ok) resolve(e)
                    else reject()
                })
                .catch(e => {
                    reject();
                })
        });
    }

    private delay = (ms: number) => new Promise((resolve, reject) => setTimeout(resolve, ms));

    private timedPromise = (successFn: () => void, ms: number) => {
        let timeout: any = undefined;
        let r: () => void;

        const timedPromise = new Promise((resolve, reject) => {
            r = reject;
            timeout = setTimeout(resolve, ms);
        });

        timedPromise.then(successFn, () => {});

        const cancel = () => {
            clearTimeout(timeout);
            r();
        }

        return {
            promise: timedPromise,
            cancel: cancel
        }
    }

    private random(min: number, max: number) {
        return (Math.random() * (max - min)) + min;
    }
}