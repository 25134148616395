import {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Content from "./Components/Content";
import {FinishResultContent} from "./Swagger";
import {Box, Typography, Button, CircularProgress} from "@mui/material";
import {ApiClient} from "./Api";

function HandInFailed() {
    const {t, i18n} = useTranslation();
    const location = useLocation();
    const state = location.state as FinishResultContent;
    const { id } = useParams();
    const navigate = useNavigate();
    const [finishing, setFinishing] = useState(false);
    const [finishResult, setFinishResult] = useState<FinishResultContent>();
    
    function finishHandIn() {
        setFinishing(true);

        ApiClient.instance.api.editorHandinFinishCreate(id || "", { danish: i18n.language === "da" })
            .then(e => {
                if (e.data.success) return navigate("receipt", {state: e.data.content});
                
                setFinishResult(e.data.content);
                setFinishing(false);
            })
            .catch(() => {
                setFinishing(false);
            });
    }
    
    return (
        <Box sx={{p: 3}}>
            <Typography>Det lykkedes ikke at aflevere, prøv igen ved at trykke på knappen</Typography>
            {state && 
                <Box><Content contentType={state.type || ""} content={state.content || ""}/></Box>
            }
            {finishing &&
                <Box sx={{p: 1}}><CircularProgress/></Box>
            }
            {!finishing && 
                <Button onClick={finishHandIn}>{t("finishHandin")}</Button>
            }
        </Box>
    )
}

export default HandInFailed;