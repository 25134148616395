import i18n from "i18next";
import Backend from 'i18next-http-backend';

import { initReactI18next } from "react-i18next";

const query = new URLSearchParams(window.location.search).get('lang');
const requestedLanguage = query ?? localStorage.getItem('editorLagnuage') ?? 'da';
const supportedLanguages = ['da', 'en'];
const language = supportedLanguages.indexOf(requestedLanguage) >= 0 ? requestedLanguage : 'da';

i18n.use(Backend)
    .use(initReactI18next)    
    .init({
        lng: language,
        fallbackLng: language,
        backend: {
            loadPath: "/assets/i18n/translations/{{lng}}.json",
        },
        interpolation: {
            escapeValue: false
        },
        react: {
            useSuspense: false
        }
    });

export default i18n;