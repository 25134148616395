import React from 'react';

function Content(p: {contentType: string, content: string}) {
    const renderContent = () => {
        if (p.contentType.toLowerCase().indexOf("text/html") >= 0) {
            return <div dangerouslySetInnerHTML={{__html: p.content}}/>
        } else if (p.contentType.toLowerCase().indexOf("application/json") >= 0) {
            return <div>
                <pre>{JSON.stringify(p.content, null, 2)}</pre>
            </div>
        } else {
            return <div>{p.content}</div>
        }
    }
    
    return renderContent();
}

export default Content;