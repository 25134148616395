function fix(text: string, keepSpaces: boolean): string {
    var fixed = text.replace(/<p>&nbsp;<\/p>/g, ""); //Fjerner p tags med kun nbsp(sker n�r to newlines efterhinanden)
    fixed = fixed.replace(/\n|\r/mg, "") // Fjerner linie skift.
    fixed = fixed.replace(/&nbsp;/mg, " ") // udskift falske spaces med rigtige spaces.
    fixed = fixed.replace(/\t/g, "") // Fjerner tabs.
    fixed = fixed.replace(/ +/mg, " ") // udskift flere efterf�lgende spaces med 1 space.
    if (!keepSpaces) {
        fixed = fixed.replace(/ /g, "") // Fjerner spaces.
    }

    fixed = fixed.replace(/<\/?.*?\/?>/g, "") // Fjerner tags.

    fixed = fixed.replace(/&.*?;/g, "_"); // Udskifter HTML entiteter med _ s� de t�ller som et tegn.
    return fixed;
}

export function charCount(text: string): number {
    return fix(text, true).length;
}

export function charNoSpacesCount(text: string): number {
    return fix(text, false).length;
}

export function pageCount(text: string): number {
    return Math.ceil(fix(text, true).length / 2400);
}