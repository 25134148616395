class Stub {
    constructor(public name: string) { }
    
    onmessage() { }
    onmessageerror() { }
    close() { }
    postMessage() { }
    addEventListener() { }
    removeEventListener() { }
    
    dispatchEvent() {
        return false;
    }
}

const broadcastChannelAvailable = !!window.BroadcastChannel;

window.BroadcastChannel = window.BroadcastChannel || Stub

export {broadcastChannelAvailable}