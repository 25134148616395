import React from 'react';
import { useParams } from 'react-router-dom';
import { ApiClient } from '../Api';
import styled from "styled-components";

const Embed = styled.embed`
  width: 100vw; 
  height: 99vh;
`

function NoteFull(){
    let { id, noteId } = useParams();
    
    const noteUrl = ApiClient.baseUrl + "/api/editor/notescollection/" + id + "/notes/" + noteId + "/download/inline"
    return (
        <Embed src={noteUrl}/>
    )
}

export default NoteFull;