import React, {useEffect, useRef, useState} from 'react';
import Editor from './Editor';
import SideMenu from './SideMenu/SideMenu';
import {FinishResultContent, HandInModel as DataHandIn} from './Swagger'
import {ApiClient} from './Api'
import {SaveMonitor, SaveStatus} from './Components/SaveMonitor';
import EditorBroadcastChannel from './EditorBroadcastChannel';
import {Button, Dialog, CircularProgress, DialogTitle, DialogContent, DialogContentText, DialogActions, Box} from "@mui/material";
import {useNavigate} from "react-router-dom";
import SaveStatusInfo from "./SaveStatusInfo";
import {appendixUrl} from './SideMenu/AppendicesLogic';
import {useTranslation} from "react-i18next";
import Content from "./Components/Content";
import ExtraMaterialInfo from "./SideMenu/ExtraMaterialInfo";

interface IProps {
    handIn: DataHandIn;
    id: string;
    onSaveStatusChanged: (s: SaveStatus) => void;
    saveStatus: SaveStatus;
}

const HandIn = (p: IProps) => {
    const [text, setText] = useState(p.handIn.data || "");
    const [appendices, setAppendices] = useState(p.handIn.appendices ?? []);
    const [open, setOpen] = useState(false);
    const [finishing, setFinishing] = useState(false);
    const [finishResult, setFinishResult] = useState<FinishResultContent>();
    const [handingInError, setHandingInError] = useState(false);
    const navigate = useNavigate();
    const isMounted = useRef(false);
    const {t, i18n} = useTranslation();

    const finishHandIn = () => {
        setFinishing(true);
        
        ApiClient.instance.api.editorHandinFinishCreate(p.id, { danish: i18n.language === "da" })
            .then(e => {
                if (e.data.success) return navigate("receipt", {state: e.data.content});
                
                setHandingInError(!e.data.success);
                setFinishResult(e.data.content);
                setFinishing(false);
            })
            .catch(() => {
                setHandingInError(true);
                setFinishing(false);
            });
    }

    useEffect(() => {
        if (isMounted.current) { // skips running the effect on initial load
            const saveMonitor = new SaveMonitor(p.onSaveStatusChanged);

            const waitLength = 1000;

            const timeout = setTimeout(() => {
                saveMonitor.ensureSave(() => ApiClient.instance.api.editorHandinResponseCreate(p.id, text));
            }, waitLength);

            return () => {
                clearTimeout(timeout);
            }
        }
    }, [text, p.id, p.onSaveStatusChanged])

    const editorRef = useRef<typeof Editor>();

    const scrollHeadingIntoView = (index: number) => {
        if (editorRef.current) {
            (editorRef.current as any).scrollHeaderIntoView(index);
        }
    }

    const insertImage = (path: string) => {
        if (editorRef.current) {
            (editorRef.current as any).insertImage(path);
        }
    }

    const replaceImage = (oldPath: string, newPath: string) => {
        if (editorRef.current) {
            (editorRef.current as any).replaceImage(oldPath, newPath);
        }
    }

    useEffect(() => {
        const bc = new EditorBroadcastChannel();

        bc.onNewAppendix = e => {
            setAppendices(old => [...old, e]);
        }

        bc.onRemoveAppendix = e => {
            setAppendices(old => old.filter(x => e.id !== x.id));
        }

        bc.onUpdateAppendix = e => {
            setAppendices(old => [...old.filter(x => e.id !== x.id), e]);
        }

        bc.onReplaceAppendix = old_new => {
            replaceImage(appendixUrl(p.id, old_new.oldAppendix), appendixUrl(p.id, old_new.newAppendix))
            setAppendices(old => [...old, old_new.newAppendix]);
        }

        return () => {
            bc.close();
        }
    });

    useEffect(() => {
        isMounted.current = true;
    });

    const showSaving = p.saveStatus !== SaveStatus.Saved;

    return (
        <article style={{display: "flex", flexFlow: "column", flex: "1 0", height: "85vh"}}>
            <section style={{display: "flex", flex: "1 0"}}>
                <Editor ref={editorRef} initialText={p.handIn.data || ""} handInId={p.id} onInputChange={setText}/>
                <SideMenu text={text} handInId={p.id} handIn={p.handIn}
                          hasAssignmentFile={p.handIn.hasAssignmentFile || false}
                          documentInfoGotoHeadingRequest={scrollHeadingIntoView}
                          extraMaterialInfo={<ExtraMaterialInfo insertImage={insertImage} handInId={p.id} appendices={appendices} setAppendices={setAppendices}/>}
                />
            </section>

            <Box sx={{pt: 3, ml: "auto"}}>
                <SaveStatusInfo show={showSaving}/>
                <Button variant="contained" onClick={() => setOpen(true)}>{t("finishHandin")}</Button>
            </Box>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>
                    {t("finishHandin")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("handinContent")}
                    </DialogContentText>
                    <ul>
                        <li key={1}>
                            <DialogContentText>{text.length === 0 ? t('yourEmptyHandin') : t("yourHandin")}</DialogContentText>
                        </li>
                        {appendices.map(a => <li key={a.id}><DialogContentText>{a.name}</DialogContentText></li>)}
                    </ul>
                    {!handingInError &&
                        <DialogContentText>
                            {!p.handIn.settings?.canReopen && <>{t("handinWarning")}<br/></> }{t("continueQuestion")}
                            {text.length === 0 ? <DialogContentText><br/>{t('emptyHandinWarning')}</DialogContentText> : <></>}
                        </DialogContentText>
                    }
                    {handingInError && finishResult && 
                        <Box>
                            <DialogContentText>
                                Aflevering mislykkedes af følgende årsag
                            </DialogContentText>
                            <Content content={finishResult.content ?? ""} contentType={finishResult.type ?? ""}/>
                            <DialogContentText>
                                Tryk på 'Aflever'-knappen for at prøve igen
                            </DialogContentText>
                        </Box>
                    }
                    {handingInError && !finishResult &&
                        <Box>
                            <DialogContentText>
                                Aflevering mislykkedes, tryk på 'Aflever'-knappen for at prøve igen
                            </DialogContentText>
                        </Box>
                    }
                </DialogContent>
                <DialogActions>
                    {finishing &&
                        <Box sx={{p: 1}}><CircularProgress/></Box>
                    }
                    {!finishing &&
                        <Box>
                            <Button variant="outlined" onClick={() => setOpen(false)}>{t("cancel")}</Button>
                            <Button sx={{ml: 2}} variant="contained" onClick={finishHandIn} autoFocus>{t("finishHandin")}</Button>
                        </Box>
                    }
                </DialogActions>
            </Dialog>
        </article>
    )
}

export default HandIn;