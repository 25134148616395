import React, {useEffect, useState} from 'react';
import styled from "styled-components";

interface IProps {
    show: boolean;
}

const Inline = styled.div`
    display: inline;
`

const Animated = styled.span<IProps>`
  ${props => {
    if (!props.show) {
      return `
        opacity: 0;
        transition: all 0ms linear 2s;
      `;
    }
  }}
`;

const Regular = styled.span<IProps>`
  ${props => {
    if (!props.show) {
      return `
        display: none;
      `;
    }
  }}
`

function SaveStatusInfo(p: { show: boolean }) {
    const [showSaved, setShowSaved] = useState(false);
    
    useEffect(() => {
        setShowSaved(false);
    }, [p.show])
    
    return (
        <Inline>
            <Regular show={showSaved}>Gemt</Regular>
            <Animated show={p.show} onTransitionEnd={() => setShowSaved(true)}>
                Gemmer...
            </Animated>
        </Inline>

    )
}

export default SaveStatusInfo;